
export const fieldTypes = {
    single: 'SINGLE',
    multi: 'MULTI'
}

//optional fields that are shared by downloads and request objects
export const optionalRequestFields = {
    model: { key: 'model', name: 'Model', fieldType: fieldTypes.single },
    productArea: { key: 'productArea', name: 'Product Area', fieldType: fieldTypes.single },
    productCategory: { key: 'productCategory', name: 'Product Category', fieldType: fieldTypes.single },
    missionSet: { key: 'missionSet', name: 'Mission Set', fieldType: fieldTypes.single },
    missionCategory: { key: 'missionCategory', name: 'Mission Category', fieldType: fieldTypes.single },
    classificationLevel: { key: 'classificationLevel', name: 'Classification Level', fieldType: fieldTypes.single },
    exportLevel: { key: 'exportLevel', name: 'Export Level', fieldType: fieldTypes.single },
    releaseTo: { key: 'releaseTo', name: 'Release To', fieldType: fieldTypes.multi },
    command: { key: 'command', name: 'Command', fieldType: fieldTypes.single },
    requestName: { key: 'requestName', name: 'Request Name', fieldType: fieldTypes.single },
    requestReason: { key: 'requestReason', name: 'Request Reason', fieldType: fieldTypes.single },
    missionImpact: { key: 'missionImpact', name: 'Mission Impact', fieldType: fieldTypes.single },
    priority: { key: 'priority', name: 'Priority', fieldType: fieldTypes.single },
}

export const priorities = {
    normal: 'NORMAL',
    high: 'HIGH'
}

export const classificationLevels = {
    unclassified: 'UNCLASSIFIED',
    confidential: 'CONFIDENTIAL',
    secret: 'SECRET',
    topSecret: 'TOP_SECRET'
}