
import {
  PRODUCT_REGION,
  PRODUCT_REGION_FILTER_LIST,
  PRODUCT_TIMELINE,
  SET_SELECTED_PRODUCT,
  CLEAR_ALL_PRODUCT_SELECTIONS,
  UPDATE_OPTIONAL_FIELD,
  DELETE_OPTIONAL_FIELD,
  SET_EDIT_MODE
} from './types'
import { clearAllSelections } from './mapSelection'
import { getProducts, clearProducts } from './productApi'
import { regionTypes } from '../../utilities/mapUtils'
import { optionalRequestFields, fieldTypes } from '../../utilities/enums'
import { getDateFragments } from '../../utilities/dateUtils'

export const clearProductSelections = () => dispatch => {
  dispatch(setSelectedProduct())
  dispatch(clearProducts())
  dispatch(setEditMode(false))
  dispatch({ type: CLEAR_ALL_PRODUCT_SELECTIONS })
}

export const setProductRegion = (item, type = regionTypes.existingItem, fetch = true) => (dispatch, getState) => {
  if (!item) { //clearing region
    dispatch({ type: PRODUCT_REGION, payload: null })
    dispatch(setSelectedProduct())
    dispatch(clearProducts())
  }
  else {
    if (type === regionTypes.existingItem) dispatch(clearAllSelections())
    dispatch({
      type: PRODUCT_REGION,
      payload: type === regionTypes.existingItem ? { type, data: item } : { type, ...item }
    })
    if (fetch) {
      const { productTimeLine } = getState().productReducer
      if (productTimeLine.start.date && productTimeLine.end.date) {
        dispatch(getProducts())
      }
    }
  }
}

export const setProductTimeLine = (item, fetch = true) => (dispatch, getState) => {
  dispatch({
    type: PRODUCT_TIMELINE,
    payload: item
  })
  if (fetch) {
    const { productRegion } = getState().productReducer
    if (productRegion) {
      dispatch(getProducts())
    }
  }
}

export const setProductRegionFilterList = list => dispatch => {
  dispatch({
    type: PRODUCT_REGION_FILTER_LIST,
    list
  })
}

export const setSelectedProduct = (item) => dispatch => {
  dispatch({
    type: SET_SELECTED_PRODUCT,
    payload: item
  })
}

export const duplicateRequest = (request, edit = false) => dispatch => {
  // console.log('duplicate')
  // console.log(request)
  dispatch(clearProductSelections())
  dispatch(setProductRegion(request.region.type === regionTypes.existingItem ? request.region.data : request.region, request.region.type, false))
  dispatch(setProductTimeLine({ start: getDateFragments(request.startDateTime), end: getDateFragments(request.endDateTime) }))
  Object.keys(request.optionalFields).forEach(field => {
    if (optionalRequestFields.hasOwnProperty(field)) dispatch(updateOptionalField(field, request.optionalFields[field], false))
  })
  dispatch(setSelectedProduct(request.product))
  if (edit) dispatch(setEditMode(true, request._id))
}

export const setEditMode = (isEditMode, requestId) => ({
  type: SET_EDIT_MODE,
  payload: { isEditMode, requestId }
})

export const updateOptionalField = (fieldName, value, fetch = true) => (dispatch, getState) => {
  if (!optionalRequestFields.hasOwnProperty(fieldName)) console.warn('cannot find optional field in enum')
  else {
    const { fieldType } = optionalRequestFields[fieldName]
    if (fieldType === fieldTypes.single) {
      if (!value) dispatch({ type: DELETE_OPTIONAL_FIELD, payload: { fieldName } })
      else dispatch({ type: UPDATE_OPTIONAL_FIELD, payload: { fieldName, value } })
      if (fieldName === optionalRequestFields.model.key) { //special case to fetch products on model change
        const { productRegion, productTimeLine } = getState().productReducer
        if (productRegion && productTimeLine.start.date && productTimeLine.end.date && fetch) {
          dispatch(getProducts())
        }
      }
    }
    else if (fieldType === fieldTypes.multi) {
      if (Array.isArray(value)) {
        if (value.length === 0) dispatch({ type: DELETE_OPTIONAL_FIELD, payload: { fieldName } })
        else dispatch({ type: UPDATE_OPTIONAL_FIELD, payload: { fieldName, value } })
      }
      else {
        let array = getState().productReducer.optionalFields[fieldName]
        if (!array) array = [value]
        else if (array.map(x => x._id).includes(value._id)) array = array.filter(x => x._id !== value._id)
        else array.push(value)
        dispatch({ type: UPDATE_OPTIONAL_FIELD, payload: { fieldName, value: array } })
      }
    }
  }
}