import React from 'react'
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SimpleBar from 'simplebar-react'
import './AccordionItem.scss'

export const AccordionItem = ({
  children,
  title,
  selectedState,
  handleChange,
  expanded,
  simpleBarHeight
}) => {
  return (
    <Accordion
      className='left-column-section'
      expanded={expanded === title}
      onChange={(e, state) => { handleChange(title, state) }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${title}bh-content`}
        id="panel1bh-header"
        className='sidebar-dashboard-expansion-panel-header'
      >
        <span className='title'>{title}</span>
        <span className='selected-state'>{selectedState ? `- ${selectedState}` : ' '}</span>
      </AccordionSummary>
      <SimpleBar style={{ height: simpleBarHeight }}>
        <AccordionDetails>
          {children}
        </AccordionDetails>
      </SimpleBar>
    </Accordion>
  )
}


AccordionItem.defaultProps = {
  simpleBarHeight: null
};
export default AccordionItem
