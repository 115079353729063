import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import SearchIcon from '@material-ui/icons/Search'
import { postRequest, updateRequest } from '../../../../store/actions/requestsApi'
import TextFieldHorizontal from '../../../../components/common/TextFieldHorizontal/TextFieldHorizontal'
import AccordionItem from '../../../../components/AccordionItem/AccordionItem'
import List from '../../../../components/common/List/List'
import ProductTile from '../../../../components/ProductTile/ProductTile'
import { setSelectedProduct, clearProductSelections, updateOptionalField } from '../../../../store/actions/productSelections'
import { setSnackbarData } from '../../../../store/actions/snackbarActions'
import { regionTypes, productTypes } from '../../../../utilities/mapUtils'
import { optionalRequestFields, priorities, classificationLevels } from '../../../../utilities/enums'
import { getRandomFromArray, getRandomInt } from '../../../../utilities/MathUtils'
import { releaseToOptions } from '../../../../data/leftPanelOptions'
import TextField from '@material-ui/core/TextField'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormLabel from '@material-ui/core/FormLabel'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import './newRequestRight.scss'

const getPanelExpandedHeight = () => {
  const numPanels = 5
  return `calc(100vh - ${(numPanels * 27) + 73}px)`
}

const NewRequestRight = ({
  currentUser,
  availableProducts,
  requestableProducts,
  productRegion,
  productTimeLine,
  optionalFields,
  postRequest,
  updateRequest,
  selectedProduct,
  setSelectedProduct,
  setSnackbarData,
  clearProductSelections,
  updateOptionalField,
  editMode
}) => {

  const history = useHistory()

  const [expanded, setExpanded] = useState()
  const [clearAlert, setClearAlert] = useState(false)
  const [availableProductsFilterQuery, setAvailableProductsFilterQuery] = useState()
  const [recommendedProducts, setRecommendedProducts] = useState([])
  const [requestableProductsFilterQuery, setRequestableProductsFilterQuery] = useState()

  const [initialNote, setInitialNote] = useState(null)

  useEffect(() => {
    setRecommendedProducts(getRandomFromArray(availableProducts, getRandomInt(2, 6)))
  }, [availableProducts])

  const handleChange = (panel, state) => setExpanded(state ? panel : false)

  const selectProduct = (data, type) => setSelectedProduct({ type, data })

  const submitDownload = () => {
    const requestObj = {
      userId: currentUser.user.id,
      type: 'download',
      existingProductId: selectedProduct.data._id,
      region: { type: regionTypes.existingItem, data: selectedProduct.data.region._id },
      startDateTime: new Date(`${productTimeLine.start.date} ${productTimeLine.start.time} GMT`),
      endDateTime: new Date(`${productTimeLine.end.date} ${productTimeLine.end.time} GMT`),
      optionalFields: optionalFields
    }
    postRequest(requestObj).then(() => {
      setSnackbarData({ class: 'success-snackbar', text: 'Downloaded Product' })
      history.push('/metoc-dashboard')
    }).catch(() => {
      setSnackbarData({ class: 'error-snackbar', text: 'An Error Occurred' })
    })
  }

  const submitRequest = () => {
    const requestObj = {
      userId: currentUser.user.id,
      type: 'request',
      requestableProductId: selectedProduct.data._id,
      startDateTime: new Date(`${productTimeLine.start.date} ${productTimeLine.start.time} GMT`),
      endDateTime: new Date(`${productTimeLine.end.date} ${productTimeLine.end.time} GMT`),
      optionalFields: optionalFields
    }
    //adding region to request
    if (productRegion.type === regionTypes.existingItem) {
      requestObj.region = { type: regionTypes.existingItem, data: productRegion.data._id }
    }
    else requestObj.region = productRegion
    if (initialNote) requestObj.initialNote = initialNote

    postRequest(requestObj).then(() => {
      setSnackbarData({ class: 'success-snackbar', text: 'Requested Product' })
      history.push('/metoc-dashboard')
    }).catch(() => {
      setSnackbarData({ class: 'error-snackbar', text: 'An Error Occurred' })
    })
  }

  const updateRequestHandler = () => {
    const requestObj = {
      userId: currentUser.user.id,
      type: 'request',
      requestableProductId: selectedProduct.data._id,
      startDateTime: new Date(`${productTimeLine.start.date} ${productTimeLine.start.time} GMT`),
      endDateTime: new Date(`${productTimeLine.end.date} ${productTimeLine.end.time} GMT`),
      optionalFields: optionalFields
    }
    //adding region to request
    if (productRegion.type === regionTypes.existingItem) {
      requestObj.region = { type: regionTypes.existingItem, data: productRegion.data._id }
    }
    else requestObj.region = productRegion

    updateRequest(editMode.requestId, requestObj).then(() => {
      setSnackbarData({ class: 'success-snackbar', text: 'Updated Request' })
      history.push('/metoc-dashboard')
    }).catch(() => {
      setSnackbarData({ class: 'error-snackbar', text: 'An Error Occurred' })
    })
  }

  return (
    <div className='new-request-right'>
      <div className='accordion-component-wrapper'>
        <AccordionItem
          simpleBarHeight={getPanelExpandedHeight()}
          title='Available Products'
          selectedState={availableProducts.length > 0 ? `${availableProducts.length} Products` : ''}
          expanded={expanded}
          handleChange={handleChange}
        >
          {availableProducts.length > 0 &&
            <>
              <div className='filter-container'>
                <TextFieldHorizontal
                  type='text'
                  labelWidth='100%'
                  label={<SearchIcon />}
                  placeholder='Search Available Products...'
                  variant='standard'
                  value={availableProductsFilterQuery}
                  onChange={e => { setAvailableProductsFilterQuery(e.target.value) }}
                />
              </div>
              <div className='flex-container-wrap'>
                {
                  availableProducts.filter(item => {
                    if (availableProductsFilterQuery) {
                      return item.name.toLowerCase().search(availableProductsFilterQuery.toLowerCase()) !== -1
                    }
                    else return true
                  }).map((item, i) => (
                    <ProductTile
                      key={i}
                      index={i}
                      data={item}
                      selectedProduct={selectedProduct}
                      onClick={() => selectProduct(item, productTypes.available)}
                    />
                  ))
                }
              </div>
            </>}
          {availableProducts.length === 0 &&
            <div className='placeholder-text'>
              <span>
                {
                  productRegion && productTimeLine.start.date && productTimeLine.end.date ?
                    'No Results' : 'Select a Region and Time Range to show Available Products'
                }
              </span>
            </div>
          }
        </AccordionItem>

        <AccordionItem
          simpleBarHeight={getPanelExpandedHeight()}
          title='Recommended Products'
          selectedState={recommendedProducts.length > 0 ? `${recommendedProducts.length} Products` : ''}
          expanded={expanded}
          handleChange={handleChange}
        >
          <div className='flex-container-wrap'>
            {
              recommendedProducts.map((item, i) => (
                <ProductTile
                  key={i}
                  index={i}
                  data={item}
                  selectedProduct={selectedProduct}
                  onClick={() => selectProduct(item, productTypes.available)}
                />
              ))
            }
          </div>
        </AccordionItem>

        <AccordionItem
          simpleBarHeight={getPanelExpandedHeight()}
          title='Request Products'
          expanded={expanded}
          handleChange={handleChange}
        >
          {productRegion && productTimeLine.start.date && productTimeLine.end.date &&
            <>
              <div className='filter-container'>
                <TextFieldHorizontal
                  type='text'
                  labelWidth='100%'
                  label={<SearchIcon />}
                  placeholder='Search Requestable Products...'
                  variant='standard'
                  value={requestableProductsFilterQuery}
                  onChange={e => { setRequestableProductsFilterQuery(e.target.value) }}
                />
              </div>
              <div className='flex-container-wrap'>
                {
                  requestableProducts.filter(item => {
                    if (requestableProductsFilterQuery) {
                      return item.name.toLowerCase().search(requestableProductsFilterQuery.toLowerCase()) !== -1
                    }
                    else return true
                  }).map((item, i) => (
                    <ProductTile
                      key={i}
                      index={i}
                      data={item}
                      selectedProduct={selectedProduct}
                      onClick={() => selectProduct(item, productTypes.requestable)}
                    />
                  ))
                }
              </div>
            </>}
          {!(productRegion && productTimeLine.start.date && productTimeLine.end.date) &&
            <div className='placeholder-text'>
              <span>Select a Region and Time Range to show Requestable Products</span>
            </div>
          }
        </AccordionItem>

        <AccordionItem
          simpleBarHeight={getPanelExpandedHeight()}
          title='Release To'
          selectedState={optionalFields[optionalRequestFields.releaseTo.key]?.map(x => x.name).join(', ')}
          expanded={expanded}
          handleChange={handleChange}
        >
          {
            releaseToOptions.map(item => (
              <List
                key={item._id}
                onClick={() => updateOptionalField(optionalRequestFields.releaseTo.key, item)}
                text={item.name}
                Id={item._id}
                styleState={optionalFields[optionalRequestFields.releaseTo.key]?.map(x => x._id)}
              />
            ))
          }
        </AccordionItem>

        <AccordionItem
          simpleBarHeight={getPanelExpandedHeight()}
          title='Checkout'
          selectedState={selectedProduct ? 'Product Selected' : ''}
          expanded={expanded}
          handleChange={handleChange}
        >
          {selectedProduct && selectedProduct.type === productTypes.available &&
            <div className='product-checkout'>
              <div className='info-container'>
                {makeInfoItems([
                  ['Product', selectedProduct.data.name],
                  ['Region', selectedProduct.data.region.name],
                  ['Model', selectedProduct.data.model.name],
                  ['Start Date & Time', formatDateTimeForDisplay(productTimeLine.start)],
                  ['End Date & Time', formatDateTimeForDisplay(productTimeLine.end)],
                  ['Product Area', optionalFields?.productArea?.name],
                  ['Product Category', optionalFields?.productCategory?.name],
                  ['Mission Set', optionalFields?.missionSet?.name],
                  ['Mission Category', optionalFields?.missionCategory?.name],
                  ['Classification Level', optionalFields?.classificationLevel?.name],
                  ['Export Level', optionalFields?.exportLevel?.name],
                  ['Release To', optionalFields?.releaseTo?.map(x => x.name).join(', ')],
                  ['Command', optionalFields?.command?.name],
                ])}
              </div>
              <div className='download-button' onClick={submitDownload}>DOWNLOAD</div>
              <div className='reset-button' onClick={() => { setClearAlert(true) }}>RESET</div>
            </div>
          }
          {selectedProduct && selectedProduct.type === productTypes.requestable &&
            <div className='product-checkout'>
              <div className='info-container'>
                {makeInfoItems([
                  ['Product', selectedProduct.data.name],
                  ['Region', productRegion.type === regionTypes.existingItem ? productRegion.data.name : productRegion.title],
                  ['Model', optionalFields?.model?.name],
                  ['Start Date & Time', formatDateTimeForDisplay(productTimeLine.start)],
                  ['End Date & Time', formatDateTimeForDisplay(productTimeLine.end)],
                  ['Product Area', optionalFields?.productArea?.name],
                  ['Product Category', optionalFields?.productCategory?.name],
                  ['Mission Set', optionalFields?.missionSet?.name],
                  ['Mission Category', optionalFields?.missionCategory?.name],
                  ['Classification Level', optionalFields?.classificationLevel?.name],
                  ['Export Level', optionalFields?.exportLevel?.name],
                  ['Release To', optionalFields?.releaseTo?.map(x => x.name).join(', ')],
                  ['Command', optionalFields?.command?.name],
                ])}
              </div>
              <div className='form'>
                <FormLabel component="legend">Priority</FormLabel>
                <RadioGroup
                  row
                  aria-label="priority"
                  name="priority"
                  value={optionalFields.priority || ''}
                  onChange={e => updateOptionalField(optionalRequestFields.priority.key, e.target.value)}
                >
                  <FormControlLabel value={priorities.normal} control={<Radio />} label="Normal" />
                  <FormControlLabel value={priorities.high} control={<Radio />} label="High" />
                </RadioGroup>
                <FormLabel component="legend">Classification Level</FormLabel>
                <RadioGroup
                  row
                  aria-label="classificationLevel"
                  name="classificationLevel"
                  value={optionalFields.classificationLevel || ''}
                  onChange={e => updateOptionalField(optionalRequestFields.classificationLevel.key, e.target.value)}
                >
                  <FormControlLabel value={classificationLevels.unclassified} control={<Radio />} label="Unclassified" />
                  <FormControlLabel value={classificationLevels.confidential} control={<Radio />} label="Confidential" />
                  <FormControlLabel value={classificationLevels.secret} control={<Radio />} label="Secret" />
                  <FormControlLabel value={classificationLevels.topSecret} control={<Radio />} label="Top Secret" />
                </RadioGroup>
                <TextField
                  label="Request Name"
                  variant='outlined'
                  value={optionalFields?.requestName}
                  onChange={e => updateOptionalField(optionalRequestFields.requestName.key, e.target.value)}
                />
                <TextField
                  label="Mission Impact"
                  variant='outlined'
                  value={optionalFields?.missionImpact}
                  onChange={e => updateOptionalField(optionalRequestFields.missionImpact.key, e.target.value)}
                />
                <TextField
                  label="Reason for Request"
                  variant='outlined'
                  value={optionalFields?.requestReason}
                  onChange={e => updateOptionalField(optionalRequestFields.requestReason.key, e.target.value)}
                />
                {!editMode.isEditMode && <TextField
                  label="Additional Note (Optional)"
                  multiline
                  rows={2}
                  variant='outlined'
                  onChange={(e) => { setInitialNote(e.target.value) }}
                />}
              </div>
              {!editMode.isEditMode && <div className='download-button' onClick={submitRequest}>REQUEST</div>}
              {editMode.isEditMode && <div className='download-button' onClick={updateRequestHandler}>UPDATE</div>}
              <div className='reset-button' onClick={() => { setClearAlert(true) }}>RESET</div>
            </div>
          }
          {!selectedProduct &&
            <div className='placeholder-text'>
              <span>No Product Selected</span>
            </div>
          }
          <Dialog
            classes={{ paper: 'reset-alert' }}
            open={clearAlert}
            onClose={() => { setClearAlert(false) }}
          >
            <DialogTitle>Are you sure?</DialogTitle>
            <DialogContent>
              <DialogContentText>
                This will reset all selections back to their defaults.
          </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button className='cancel' onClick={() => { setClearAlert(false) }} >
                Cancel
          </Button>
              <Button className='confirm' onClick={() => { clearProductSelections(); setClearAlert(false) }} autoFocus>
                Reset
          </Button>
            </DialogActions>
          </Dialog>
        </AccordionItem>
      </div>
    </div>
  )
}
const mapStateToProps = ({ productReducer, auth }) => ({
  currentUser: auth.currentUser,
  availableProducts: productReducer.products,
  requestableProducts: productReducer.options ? productReducer.options.requestableProducts : [],
  productRegion: productReducer.productRegion,
  productTimeLine: productReducer.productTimeLine,
  optionalFields: productReducer.optionalFields,
  selectedProduct: productReducer.selectedProduct,
  editMode: productReducer.editMode
})
export default connect(mapStateToProps, {
  postRequest,
  updateRequest,
  setSelectedProduct,
  updateOptionalField,
  clearProductSelections,
  setSnackbarData
})(NewRequestRight)

const formatDateTimeForDisplay = (dateTime) => {
  return `${new Date(dateTime.date).toLocaleDateString('default', { month: 'short', day: 'numeric', year: 'numeric' })} ${dateTime.time} GMT`
}

const makeInfoItems = array => {
  return array.map((item, i) => {
    if (item[1]) return (
      <div key={i} className='info-item'>
        <span className='key'>{`${item[0]}:`}</span>
        <span className='value'>{item[1]}</span>
      </div>
    )
    else return null
  })
}