import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Ion } from "cesium";

Ion.defaultAccessToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJhMzBiMDFhOC0wNWQyLTRkNGItODkwNy04ZjM3MjZiMzIyZDAiLCJpZCI6NTY2MjYsImlhdCI6MTYyMTYwNjU3Nn0.taXpuF_K80Otnwo_QOiH3CVPDRTM1qS7saYGdq0RyxY";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
