import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store';
import './App.scss';
import 'simplebar/dist/simplebar.min.css';

import MainNavBar from './components/common/MainNavBar/mainNavBar';
import Footer from './components/common/Footer/Footer'

/* LOGIN AUTHENTICATION IMPORTS */
import jwt_decode from 'jwt-decode';
import setAuthToken from './utilities/setAuthToken';
import { setCurrentUser, logoutUser } from './store/actions/authActions';
import { getOptions } from './store/actions/productApi';
import { clearCurrentProfile } from './store/actions/profileActions';
import PrivateRoute from './components/common/PrivateRoute';
/* END LOGIN AUTHENTICATION IMPORTS */

import Login from './pages/auth/Login/Login';
import SmeHome from './pages/SmeScreens/SmeHome/SmeHome';
import MetocDashboard from './pages/MetocScreens/MetocDashboard/MetocDashboard';
import NewRequest from './pages/MetocScreens/NewRequest/NewRequest';
import 'react-calendar/dist/Calendar.css';

/* LOGIN AUTHENTICATION REQUIREMENTS */
// Check for token
if (localStorage.jwtToken) {
  //Set auth token header auth
  setAuthToken(localStorage.jwtToken);
  // Decode token and get user info and experation. Checks with every page request.
  const decoded = jwt_decode(localStorage.jwtToken);
  // Set user and isAuthenticated
  store.dispatch(setCurrentUser(decoded));

  //Check for expired token
  const currentTime = Date.now() / 3000;
  if (decoded.exp < currentTime) {
    //Logout User
    store.dispatch(logoutUser());
    //Clear current Profile
    store.dispatch(clearCurrentProfile());
    // Redirect to login
    window.location.href = '/';
  }

}
/* LOGIN AUTHENTICATION REQUIREMENTS */

function App() {

  useEffect(() => {
    store.dispatch(getOptions())
  }, [])
  return (
    <Provider store={store}>
      <div className="App-Main-Wrapper">
        <Router>
          <MainNavBar />
          <Switch>
            <Route exact path="/" component={Login} />
          </Switch>
          <Switch>
            <Route exact path="/login" component={Login} />
          </Switch>

          <Switch>
            <PrivateRoute exact path="/sme-home" component={SmeHome} userType='SME' />
          </Switch>

          {/* METOC PAGES START*/}
          <Switch>
            <PrivateRoute exact path="/metoc-dashboard" component={MetocDashboard} userType='METOC' />
          </Switch>

          <Switch>
            <PrivateRoute exact path="/new-request" component={NewRequest} userType='METOC' />
          </Switch>
          {/* METOC PAGES END*/}
          <Footer />
        </Router>
      </div>
    </Provider>
  );
}

export default App;
