import React from 'react'
import CesiumMap from '../../../components/CesiumMap/CesiumMap'
import NewRequestLeft from './NewRequestLeft/NewRequestLeft'
import NewRequestRight from './NewRequestRight/NewRequestRight';

import './newRequest.scss';

const NewRequest = () => {


    return (
        <div className='new-request'>

            <div className='two-column-wrapper'>
                <div className='left'>
                    <NewRequestLeft />
                </div>

                <div className='middle'>
                    <CesiumMap />
                </div>

                <div className='right'>
                    <NewRequestRight />
                </div>
            </div>
        </div>
    )
}


export default NewRequest;
