import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { loginUser } from '../../../store/actions/authActions'
import TextFieldInput from '../../../components/common/TextFieldInput/TextFieldInput'
import ButtonDefault from '../../../components/common/ButtonDefault/ButtonDefault'
import Button from '@material-ui/core/Button'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import Tooltip from '@material-ui/core/Tooltip'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import './Login.scss'

export const Login = ({
    history,
    currentUser,
    loginUser,
    auth,
    errors
}) => {

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [errorsState, setErrors] = useState({})
    const [showTooltip, setShowTooltip] = React.useState(false)


    useEffect(() => {
        setErrors(errors)
    }, [errors])



    const checkTokenDetails = () => {
        switch (currentUser.user.usertype) {
            case 'ADMIN':
                history.push('/main-page')
                break;
            case 'SME':
                history.push('/sme-home')
                break;
            case 'METOC':
                history.push('/metoc-dashboard')
                break;
            default:
                console.log(`Sorry, no login`);
        }
    }


    const onSubmit = (e) => {
        e.preventDefault();
        const userData = {
            email: email,
            password: password,
        }
        loginUser(userData);
    }

    if (auth.isAuthenticated) {
        checkTokenDetails()
    }


    return (
        <div className="login">
            <div className='login-form'>

                <div className='icon-container'>
                    <AccountCircleIcon style={{ fontSize: '150px' }} />
                </div>

                <div className='form-box'>

                    <form onSubmit={onSubmit}>
                        <div>
                            <TextFieldInput
                                label="Email Address"
                                name="email"
                                type="email"
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                                error={errorsState.email}
                                helperText={errorsState.email}
                                variant='filled'
                            />
                        </div>


                        <div style={{ marginTop: '20px' }}>
                            <TextFieldInput type="password"
                                className={classnames('form-control form-control-lg', { 'is-invalid': errorsState.password })}
                                label="Password"
                                name="password"
                                error={errorsState.password}
                                helperText={errorsState.password}
                                value={password}
                                onChange={e => setPassword(e.target.value)}
                                variant='filled'
                            />
                        </div>

                        <div className='submit-button'>
                            <ButtonDefault
                                type='submit'
                                value='Submit'
                                variant='contained'
                                color='primary'
                            />
                        </div>
                        <div className='submit-button'>
                            <ClickAwayListener onClickAway={() => { setShowTooltip(false) }}>
                                <Tooltip
                                    PopperProps={{
                                        disablePortal: true,
                                    }}
                                    arrow
                                    onClose={() => { setShowTooltip(false) }}
                                    open={showTooltip}
                                    disableFocusListener
                                    disableHoverListener
                                    disableTouchListener
                                    title="Not available in prototype"
                                >
                                    <Button
                                        className='buttonDefault'
                                        variant='contained'
                                        color='primary'
                                        onClick={() => { setShowTooltip(true) }}
                                    >
                                        Login with CAC
                                        </Button>
                                </Tooltip>
                            </ClickAwayListener>
                            {/* <ButtonDefault
                                value='Login with CAC'
                                variant='contained'
                                color='primary'
                            /> */}
                        </div>
                    </form>

                </div>

            </div>
        </div>
    )
}

Login.propTypes = {
    loginUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    currentUser: PropTypes.object
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    errors: state.errors,
    currentUser: state.auth.currentUser
})

export default connect(mapStateToProps, { loginUser })(Login);

