import {
  PRODUCT_REGION,
  PRODUCT_REGION_FILTER_LIST,
  PRODUCT_TIMELINE,
  GET_PRODUCTS,
  CLEAR_PRODUCTS,
  GET_OPTIONS,
  SET_SELECTED_PRODUCT,
  CLEAR_ALL_PRODUCT_SELECTIONS,
  UPDATE_OPTIONAL_FIELD,
  DELETE_OPTIONAL_FIELD,
  SET_EDIT_MODE
} from '../actions/types'
import moment from 'moment'

const initialState = {
  products: [],
  options: null,
  productRegion: null,
  productRegionFilterList: [],
  productTimeLine: {
    start: {
      date: moment().add(1, 'month').utc().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).format('YYYY-MM-DD'),
      time: '00:00'
    },
    end: {
      date: moment().utc().add(1, 'month').add(1, 'day').set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).format('YYYY-MM-DD'),
      time: '00:00'
    },
  },
  optionalFields: {},
  selectedProduct: null,
  editMode: { isEditMode: false }
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_PRODUCTS:
      return {
        ...state,
        products: action.payload
      };
    case CLEAR_PRODUCTS:
      return {
        ...state,
        products: initialState.products
      }
    case GET_OPTIONS:
      return {
        ...state,
        options: action.payload
      };
    case PRODUCT_REGION:
      return {
        ...state,
        productRegion: action.payload
      };
    case PRODUCT_REGION_FILTER_LIST:
      return {
        ...state,
        productRegionFilterList: action.list
      }
    case PRODUCT_TIMELINE:
      return {
        ...state,
        productTimeLine: { ...state.productTimeLine, ...action.payload },
      };
    case SET_SELECTED_PRODUCT:
      return {
        ...state,
        selectedProduct: action.payload,
      };
    case CLEAR_ALL_PRODUCT_SELECTIONS:
      return {
        ...initialState,
        options: state.options,
        productRegionFilterList: state.productRegionFilterList
      }
    case UPDATE_OPTIONAL_FIELD:
      return {
        ...state,
        optionalFields: {
          ...state.optionalFields,
          [action.payload.fieldName]: action.payload.value
        }
      }
    case DELETE_OPTIONAL_FIELD:
      const { [action.payload.fieldName]: value, ...rest } = state.optionalFields
      return {
        ...state,
        optionalFields: rest
      }
    case SET_EDIT_MODE:
      return {
        ...state,
        editMode: action.payload
      }
    default:
      return state;
  }
}