import { GET_PRODUCTS, CLEAR_PRODUCTS, GET_OPTIONS, GET_ERRORS } from './types'
import { devBaseURL } from '../api'
import { regionTypes, getIntersectingRegions } from '../../utilities/mapUtils'


export const getProducts = () => async (dispatch, getState) => {
  const { productRegion, productTimeLine, optionalFields: { model }, options: { regions } } = getState().productReducer
  const params = new URLSearchParams()

  if (productRegion.type === regionTypes.existingItem) {
    if (Array.isArray(productRegion.data)) productRegion.data.forEach(region => { params.append('regionId', region._id) })
    else params.append('regionId', productRegion.data._id)
  }
  else if (productRegion.type === regionTypes.shape) {
    const intersectingRegions = getIntersectingRegions(productRegion, regions)
    intersectingRegions.forEach(region => params.append('regionId', region._id))
  }

  if (productTimeLine.start) params.append('startDateTime', new Date(`${productTimeLine.start.date}T${productTimeLine.start.time}Z`).toISOString())
  if (productTimeLine.end) params.append('endDateTime', new Date(`${productTimeLine.end.date}T${productTimeLine.end.time}Z`).toISOString())
  if (model) {
    if (Array.isArray(model)) model.forEach(m => { params.append('modelId', m._id) })
    else params.append('modelId', model._id)
  }
  
  try {
    const res = await devBaseURL.get(`/api/products?` + params.toString())
    dispatch({
      type: GET_PRODUCTS,
      payload: res.data
    })
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: { msg: err.response.statusText, status: err.response.status },
    })
  }
}

export const clearProducts = () => ({
  type: CLEAR_PRODUCTS
})

export const getOptions = () => async dispatch => {
  try {
    const res = await devBaseURL.get(`/api/products/options`);
    dispatch({
      type: GET_OPTIONS,
      payload: res.data
    })
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err,
    })
  }
}
