import {
    SET_SELECTION_MODE,
    SET_SELECTION_POINT,
    SET_SELECTION_RECT,
    SET_SELECTION_CIRCLE,
    ADD_SELECTION_WAYPOINT,
    CLEAR_ALL_SELECTIONS
} from '../actions/types'
import { Cartographic, Rectangle } from 'cesium'
import { selectionModes, selectionSources } from '../../utilities/mapUtils'

const initState = {
    mode: selectionModes.point,
    point: new Cartographic(),
    rectangle: new Rectangle(),
    circle: { position: { latitude: 0, longitude: 0, height: 0 }, radius: 500 },
    waypoints: [],
    selectionToShow: null,
    selectionSource: selectionSources.menu,
    clearSelectionEvent: false //flipping boolean to trigger useEffect in CesiumMap
}

export default function (state = initState, action) {
    switch (action.type) {
        case SET_SELECTION_MODE:
            return { ...state, mode: action.mode }
        case SET_SELECTION_POINT:
            return { ...state, point: action.point, selectionToShow: selectionModes.point, selectionSource: action.source }
        case SET_SELECTION_RECT:
            return { ...state, rectangle: action.rectangle, selectionToShow: selectionModes.rectangle }
        case SET_SELECTION_CIRCLE:
            return { ...state, circle: action.circle, selectionToShow: selectionModes.circle }
        case ADD_SELECTION_WAYPOINT:
            return { ...state, waypoints: [...state.waypoints, action.waypoint], selectionToShow: selectionModes.waypoint }
        case CLEAR_ALL_SELECTIONS:
            return {
                ...state,
                point: initState.point,
                rectangle: initState.rectangle,
                circle: initState.circle,
                waypoints: initState.waypoints,
                selectionToShow: null,
                clearSelectionEvent: action.clearSelectionEvent
            }
        default:
            return { ...state }
    }
}