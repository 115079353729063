import React from "react";
import ReactEcharts from 'echarts-for-react'


export const PieChart = ({
	titleText,
	data
}) => {



	const getOption = () => {

		var legendData = Object.keys(data).map(val => data[val].name);

		let option = {
			responsive: true,
			maintainAspectRatio: false,
			height: '260',
			title: {
				text: titleText,
				// subtext: '虚构数据',
				left: 'center',
				font: 'white',
				textStyle: {
					color: 'white',
					fontSize: 13
				}
			},
			tooltip: {
				trigger: 'item',
				formatter: '{b} : {c} ({d}%)'
			},
			legend: {
				// show: false
				bottom: -5,
				// left: 'center',
				data: legendData,
				textStyle: {
					color: 'gray',
				}
			},
			series: [
				{
					type: 'pie',
					radius: '55%',
					center: ['50%', '50%'],
					selectedMode: 'single',
					// color: ['#2798F9','#BDE0FE', '#F1F8FE', '#A8C9E5', '#115893', '#2772B2', '#7FC4FC'],
					color: ['#1282ca', '#7bc7e5', '#d5e3e9', '#fed727', '#7d6b9d', '#595a5a', '#e5018c', '#fed727'],

					data: data,
					emphasis: {
						itemStyle: {
							shadowBlur: 10,
							shadowOffsetX: 0,
							shadowColor: 'rgba(0, 0, 0, 0.5)'
						}
					}
				}
			],
			label: {
				fontSize: 8
			}
		};
		return option
	}

	return (
		<>
			{data && <ReactEcharts option={getOption()} style={{ height: '100%', width: '100%' }} opts={{ renderer: "svg" }} />}
			{!data && <p>Loading...</p>}
		</>
	)
}



export default PieChart