import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import SearchIcon from '@material-ui/icons/Search'
import TextFieldHorizontal from '../common/TextFieldHorizontal/TextFieldHorizontal'
import List from '../common/List/List'
import { setProductRegionFilterList, setProductRegion } from '../../store/actions/productSelections'
import { setSelectionPoint, clearAllSelections } from '../../store/actions/mapSelection'
import { selectionModes, selectionSources, getLatLonFromString, regionTypes } from '../../utilities/mapUtils'
import { radiansToDegrees } from '../../utilities/MathUtils'
import './RegionMenu.scss'

const RegionMenu = ({
    options,
    productRegion,
    productRegionFilterList,
    mapSelection,
    setProductRegion,
    setProductRegionFilterList,
    setSelectionPoint,
    clearAllSelections
}) => {

    const [regionFilterQuery, setRegionFilterQuery] = useState('')

    useEffect(() => {
        if (options) setProductRegionFilterList(options.regions)
    }, [options, setProductRegionFilterList])

    useEffect(() => { //updates selected point latLon from map to filter bar
        if (mapSelection.selectionSource !== selectionSources.menu
            && productRegion && productRegion.type === regionTypes.shape) {
            setRegionFilterQuery(mapSelection.point.latitude.toFixed(4) + ', ' + mapSelection.point.longitude.toFixed(4))
        }
    }, [mapSelection.point, mapSelection.selectionSource, productRegion, setRegionFilterQuery])

    const filterRegion = e => {
        e.preventDefault()
        const value = e.target.value
        setRegionFilterQuery(value)
        const point = getLatLonFromString(value)
        if (point) {
            setProductRegionFilterList(options.regions)
            setSelectionPoint(point, selectionSources.menu)
        }
        else { //no latLon from string
            setProductRegionFilterList(options.regions.filter(item => {
                return item.name.toLowerCase().search(value.toLowerCase()) !== -1
            }))
        }
    }

    const clearSelectionAndRegion = () => {
        setRegionFilterQuery('')
        clearAllSelections()
        setProductRegion()
    }

    const renderMapSelection = () => {
        switch (mapSelection.selectionToShow) {
            case selectionModes.rectangle:
                return (
                    <div className='RegionMenu-map-selection-container'>
                        <h1>Custom Rectangle at:</h1>
                        <p>East: {radiansToDegrees(mapSelection.rectangle.east) + '°'}</p>
                        <p>West: {radiansToDegrees(mapSelection.rectangle.west) + '°'}</p>
                        <p>South: {radiansToDegrees(mapSelection.rectangle.south) + '°'}</p>
                        <p>North: {radiansToDegrees(mapSelection.rectangle.north) + '°'}</p>
                        <div className='clear-button' onClick={clearSelectionAndRegion}>Clear</div>
                    </div>
                )
            case selectionModes.circle:
                return (
                    <div className='RegionMenu-map-selection-container'>
                        <h1>Custom Circle:</h1>
                        <p>Center Latitude: {mapSelection.circle.position.latitude.toFixed(4) + '°'}</p>
                        <p>Center Longitude: {mapSelection.circle.position.longitude.toFixed(4) + '°'}</p>
                        <p>Radius: {mapSelection.circle.radius.toFixed(0) + ' meters'}</p>
                        <div className='clear-button' onClick={clearSelectionAndRegion}>Clear</div>
                    </div>
                )
            case selectionModes.waypoint:
                return (
                    <div className='RegionMenu-map-selection-container waypoint'>
                        <h1>Custom Route with Waypoints:</h1>
                        {mapSelection.waypoints.map((waypoint, i) => {
                            return <p key={i}>{waypoint.latitude.toFixed(4) + '°, ' + waypoint.longitude.toFixed(4) + '°'}</p>
                        })}
                        <div className='clear-button' onClick={clearSelectionAndRegion}>Clear</div>
                    </div>
                )
            default:
                return null
        }
    }

    if (mapSelection.selectionToShow && mapSelection.selectionToShow !== selectionModes.point) return renderMapSelection()
    else return (
        <>
            <div className='filter-container'>
                <TextFieldHorizontal
                    type='text'
                    labelWidth='100%'
                    label={<SearchIcon />}
                    placeholder='Search Region or Input Lat, Long'
                    variant='standard'
                    value={regionFilterQuery}
                    onChange={e => filterRegion(e)}
                />
            </div>
            { productRegionFilterList.length === 0 ? <p>No results</p> :
                productRegionFilterList.map(item => (
                    <List
                        key={item._id}
                        Id={item._id}
                        onClick={selected => setProductRegion(selected ? null : item)}
                        text={item.name}
                        styleState={productRegion && productRegion.type === regionTypes.existingItem ? productRegion.data._id : null}
                    />
                ))
            }
        </>

    )
}

const mapStateToProps = ({ productReducer, mapSelection }) => ({
    options: productReducer.options,
    productRegion: productReducer.productRegion,
    productRegionFilterList: productReducer.productRegionFilterList,
    mapSelection
})

export default connect(mapStateToProps, {
    setProductRegionFilterList,
    setProductRegion,
    setSelectionPoint,
    clearAllSelections
})(RegionMenu)