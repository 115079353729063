import { SET_SNACKBAR_DATA } from '../actions/types'

const initState = {
    class: undefined,
    text: undefined,
    autoHide: true
}

export default function (state = initState, action) {
    switch (action.type) {
        case SET_SNACKBAR_DATA:
            return {
                ...state,
                class: action.data.class,
                text: action.data.text,
                autoHide: action.data.autoHide ? action.data.autoHide : true
            }
        default:
            return state
    }
}