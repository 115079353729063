import moment from 'moment'

//checks if a date is between a date range. Expects JS Date objects
export const inRange = (date, start, end) => {
    const dateInMS = date.getTime()
    return dateInMS >= start.getTime() && dateInMS <= end.getTime()
}

//gets YYYY-MM-DD and HH:mm from JS Date
export const getDateFragments = dateTime => {
    const date = moment(dateTime).utc().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).format('YYYY-MM-DD')
    const time = moment(dateTime).utc().format('HH:mm')
    return { date, time }
}