import React from 'react'
import './SmeHome.scss'

const SmeHome = () => {
    return (
        <div className>
            <h1>HELLO SME</h1>
        </div>
    )
}

export default SmeHome;
