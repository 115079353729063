import React, { useState } from 'react'
import { connect } from 'react-redux'
import List from '../../../../components/common/List/List'
import AccordionItem from '../../../../components/AccordionItem/AccordionItem'
import RegionMenu from '../../../../components/RegionMenu/RegionMenu'
import 'react-calendar/dist/Calendar.css';
import TimeRange from '../../../../components/TimeRange/TimeRange'
import { regionTypes } from '../../../../utilities/mapUtils'
import { optionalRequestFields } from '../../../../utilities/enums'
import { updateOptionalField } from '../../../../store/actions/productSelections'
import {
  productAreaOptions,
  productCategoryOptions,
  missionSetOptions,
  missionCategoryOptions,
  commandOptions
} from '../../../../data/leftPanelOptions'
import './newRequestLeft.scss'

const getPanelExpandedHeight = () => {
  const numPanels = 8
  return `calc(100vh - ${(numPanels * 27) + 73}px)`
}

const NewRequestLeft = ({
  options,
  productRegion,
  productTimeLine,
  optionalFields,
  updateOptionalField
}) => {

  const [expanded, setExpanded] = useState('Region')

  const handleChange = (panel, state) => setExpanded(state ? panel : false)

  const getSelectedRegionName = () => {
    if (!productRegion) return null
    else if (productRegion.type === regionTypes.existingItem) return productRegion.data.name
    else return productRegion.title
  }

  return (
    <>
      {options && <div className='accordion-component-wrapper'>
        <AccordionItem
          simpleBarHeight={getPanelExpandedHeight()}
          title='Region'
          selectedState={getSelectedRegionName()}
          expanded={expanded}
          handleChange={handleChange}
        >
          <RegionMenu />
        </AccordionItem>

        <AccordionItem
          simpleBarHeight={getPanelExpandedHeight()}
          title='Time'
          selectedState={productTimeLine.end.date ? `${productTimeLine.start.date} | ${productTimeLine.end.date}` : ''}
          expanded={expanded}
          handleChange={handleChange}
        >
          <TimeRange />
        </AccordionItem>

        <AccordionItem
          simpleBarHeight={getPanelExpandedHeight()}
          title='Model'
          selectedState={optionalFields[optionalRequestFields.model.key]?.name}
          expanded={expanded}
          handleChange={handleChange}
        >
          {options.models.map(item => (
            <List
              key={item._id}
              onClick={selected => { updateOptionalField(optionalRequestFields.model.key, selected ? null : item) }}
              text={item.name}
              Id={item._id}
              styleState={optionalFields[optionalRequestFields.model.key]?._id}
            />
          ))
          }
        </AccordionItem>

        <AccordionItem
          simpleBarHeight={getPanelExpandedHeight()}
          title='Product Area'
          selectedState={optionalFields[optionalRequestFields.productArea.key]?.name}
          expanded={expanded}
          handleChange={handleChange}
        >
          {!productAreaOptions ? <p>loading</p> : productAreaOptions.map(item => (
            <List
              key={item._id}
              onClick={selected => updateOptionalField(optionalRequestFields.productArea.key, selected ? null : item)}
              text={item.name}
              Id={item._id}
              styleState={optionalFields[optionalRequestFields.productArea.key]?._id}
            />
          ))
          }
        </AccordionItem>

        <AccordionItem
          simpleBarHeight={getPanelExpandedHeight()}
          title='Product Category'
          selectedState={optionalFields[optionalRequestFields.productCategory.key]?.name}
          expanded={expanded}
          handleChange={handleChange}
        >
          {
            productCategoryOptions.map(item => (
              <List
                key={item._id}
                onClick={selected => updateOptionalField(optionalRequestFields.productCategory.key, selected ? null : item)}
                text={item.name}
                Id={item._id}
                styleState={optionalFields[optionalRequestFields.productCategory.key]?._id}
              />
            ))
          }
        </AccordionItem>

        <AccordionItem
          simpleBarHeight={getPanelExpandedHeight()}
          title='Mission Set'
          selectedState={optionalFields[optionalRequestFields.missionSet.key]?.name}
          expanded={expanded}
          handleChange={handleChange}
        >
          {
            missionSetOptions.map(item => (
              <List
                key={item._id}
                text={item.name}
                onClick={selected => updateOptionalField(optionalRequestFields.missionSet.key, selected ? null : item)}
                Id={item._id}
                styleState={optionalFields[optionalRequestFields.missionSet.key]?._id}
              />
            ))
          }
        </AccordionItem>

        <AccordionItem
          simpleBarHeight={getPanelExpandedHeight()}
          title='Mission Category'
          selectedState={optionalFields[optionalRequestFields.missionCategory.key]?.name}
          expanded={expanded}
          handleChange={handleChange}
        >
          {
            missionCategoryOptions.map(item => (
              <List
                key={item._id}
                text={item.name}
                onClick={selected => updateOptionalField(optionalRequestFields.missionCategory.key, selected ? null : item)}
                Id={item._id}
                styleState={optionalFields[optionalRequestFields.missionCategory.key]?._id}
              />
            ))
          }
        </AccordionItem>

        <AccordionItem
          simpleBarHeight={getPanelExpandedHeight()}
          title='Command'
          selectedState={optionalFields[optionalRequestFields.command.key]?.name}
          expanded={expanded}
          handleChange={handleChange}
        >
          {
            commandOptions.map(item => (
              <List
                key={item._id}
                text={item.name}
                onClick={selected => updateOptionalField(optionalRequestFields.command.key, selected ? null : item)}
                Id={item._id}
                styleState={optionalFields[optionalRequestFields.command.key]?.name}
              />
            ))
          }
        </AccordionItem>
      </div>}
    </>
  )
}

const mapStateToProps = state => ({
  options: state.productReducer.options,
  productRegion: state.productReducer.productRegion,
  productTimeLine: state.productReducer.productTimeLine,
  optionalFields: state.productReducer.optionalFields
});

export default connect(mapStateToProps, {
  updateOptionalField
})(NewRequestLeft)