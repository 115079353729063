import { GET_ERRORS, SET_CURRENT_USER } from './types';
import axios from 'axios';
 import setAuthToken from '../../utilities/setAuthToken';
 import jwt_decode from 'jwt-decode';
 import { devBaseURL } from '../api'; 

export const registerUser = (userData, history) => dispatch => {
    axios
    .post('/api/users/register', userData)
    .then(res => history.push('/login'))
    .catch(err =>  
        dispatch( {
            type: GET_ERRORS,
            payload: err.response.data
        })
    )
}; 


export const loginUser =(userData) => async dispatch => {
    try{
        const res =  await devBaseURL.post(`/api/auth/login`, userData)
          //Save to localstorage
          const {token} = res.data;
          // Set token to local storage
          localStorage.setItem('jwtToken', token);
          // Set token to Auth header
          setAuthToken(token);
          //Decode token to get user data
          const decoded = jwt_decode(token);
          // Set current user
          dispatch(setCurrentUser(decoded));

    }catch(err){
        dispatch({
            type: GET_ERRORS,
            payload: err.response.data
        })
    }
}


// Set logged in user
export const setCurrentUser = decoded => {
    return {
      type: SET_CURRENT_USER,
      payload: decoded
    };
  };
  
  // Log user out
  export const logoutUser = () => dispatch => {
    // Remove token from localStorage
    localStorage.removeItem('jwtToken');
    // Remove auth header for future requests
    setAuthToken(false);
    // Set current user to {} which will set isAuthenticated to false
    dispatch(setCurrentUser({}));
};