import React from 'react';
import TextField from '@material-ui/core/TextField';

import PropTypes from 'prop-types';
import './textFieldHorizontal.scss';

const TextFieldHorizontal = ({
  name,
  placeholder,
  value,
  label,
  error,
  variant,
  type,
  defaultValue,
  onChange,
  disabled,
  helperText,
}) => {
  return (
    <div className='input-group-horizontal'>
    <label> {label} </label>
    <TextField
        name={name}
        label=""
        type={type}
        variant={variant}
        defaultValue={defaultValue}
        onChange={onChange}
        value={value}
        error={error}
        helperText={helperText}
        autoComplete='no'
        placeholder={placeholder}
        InputLabelProps={{
        shrink: true,
        }}
    />
</div> 
    // <div className="form-input">
    //   <label>{label}</label>
    //   <TextField
    //     type={type}
    //     name={name}
    //     value={value}
    //     onChange={onChange}
    //     disabled={disabled}
    //     label={label}
    //     variant={variant}
    //     error={error}
    //     helperText={helperText}
    //     placeholder={placeholder}

    //   />
    // </div>
  );
};

TextFieldHorizontal.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
  info: PropTypes.string,
  error: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.string,
  label: PropTypes.any,
  variant: PropTypes.string
};

TextFieldHorizontal.defaultProps = {
  type: 'text',
  value: '',
  label: ''
};

export default TextFieldHorizontal;