export const mostRequestedProducts = [
    { name: 'Sea Surface Temperature', data: 4322 },
    { name: 'Sea Level Pressure', data: 4210 },
    { name: 'Wind Wave Period & Direction', data: 3898 },
    { name: '6000ft Wind Barbs', data: 2503 },
    { name: 'Tropopause Pressure', data: 2454 },
    { name: 'White Cap Probability', data: 2289 },
    { name: 'K-Index', data: 1865 },
    { name: 'Magnetic Ditch Headings', data: 1661 },
    { name: 'Surface Streamlines', data: 1328 },
    { name: '850 hPa Temperature', data: 992 },
    { name: 'Probability of Sig. Wave Heights', data: 782 },
    { name: 'Dust Surface Visibility', data: 543 },
    { name: 'Low-Mid Trop PW and Flow', data: 430 },
    { name: '2m Temperature', data: 422 },
    { name: 'Dust Optical Depth', data: 302 },
    { name: '1000mb Geopotential Height', data: 282 },
    { name: 'Evaporative Duct Height', data: 280 },
    { name: 'Altimeter Setting', data: 252 },
].reverse()


export const productByAgency = [
    {
        value: 392,
        name: 'NAVO'
    },
    {
        value: 100,
        name: 'FWC-N'
    },
    {
        value: 74,
        name: 'JTWC'
    },
    {
        value: 276,
        name: 'NOAC-Y'
    },
    {
        value: 900,
        name: 'FNMOC'
    },
    {
        value: 56,
        name: 'FWC-EC'
    },
    {
        value: 230,
        name: 'USNO'
    },
]

export const productByModel = [
    {
        value: 430,
        name: 'NVG'
    },
    {
        value: 376,
        name: 'GFS'
    },
    {
        value: 201,
        name: 'COAMPS'
    },
    {
        value: 177,
        name: 'ENS'
    },
    {
        value: 98,
        name: 'NUOPS'
    },
    {
        value: 120,
        name: 'Other'
    },
]