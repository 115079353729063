import React from 'react'
import Calendar from 'react-calendar';
import { setPastRequestSelected } from '../../store/actions/requestsApi';
import { connect } from 'react-redux';
import { inRange } from '../../utilities/dateUtils'
import './RequestCalendar.scss'

const RequestCalendar = ({
    requests,
    requestSelected,
    setPastRequestSelected
}) => {

    const findRequest = date => {
        const found = requests.find(request => inRange(date, request.startDateTime, request.endDateTime))
        if (found) {
            if (requestSelected && requestSelected._id === found._id) setPastRequestSelected(null)
            else setPastRequestSelected(found)
        }
    }


    return (
        <div>
            <Calendar
                onChange={value => findRequest(value)}

                tileClassName={({ date }) => {
                    if (requestSelected && inRange(date, requestSelected.startDateTime, requestSelected.endDateTime))
                        return 'requestDatesSelected'
                    else return requests.some(request =>
                        inRange(date, request.startDateTime, request.endDateTime)
                    ) ? 'requestDates' : null
                }}
            />
        </div>
    )
}


const mapStateToProps = state => ({
    requests: state.requestsReducer.requests,
    requestSelected: state.requestsReducer.requestSelected
});

export default connect(mapStateToProps, { setPastRequestSelected })(RequestCalendar)