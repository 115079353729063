import React from "react"
import ReactEcharts from 'echarts-for-react'


const BarChart = ({ data }) => {

	const getOption = () => {
		var productNames = Object.keys(data).map(val => data[val].name);
		var seriesData = Object.keys(data).map(val => data[val].data);


		let option = {
			tooltip: {
				trigger: 'axis',
				axisPointer: {
					type: 'shadow'
				}
			},
			legend: {
				data: productNames,

			},
			grid: {
				top: '0%',
				left: '1%',
				right: '4%',
				bottom: '0%',
				containLabel: true
			},
			xAxis: {
				type: 'value',
				boundaryGap: [0, 0.01],
				axisLabel: {
					textStyle: {
						color: 'white'
					}
				}
			},
			yAxis: {
				type: 'category',
				data: productNames,
				axisLabel: {
					textStyle: {
						color: 'white'
					}
				}
			},
			series: [
				{
					name: 'Requests',
					type: 'bar',
					data: seriesData
				}
			],
			color: ['#1282ca'],


		};

		return option
	}

	if (data) return (
		<ReactEcharts
			option={getOption()}
			style={{ width: '450px', height: '100%' }}
			opts={{ renderer: "svg" }}
		/>
	)
	else return <p>Loading...</p>
}

export default BarChart