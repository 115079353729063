import React from 'react'
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker'
import TimePicker from './TimePicker/TimePicker'
import "react-datepicker/dist/react-datepicker.css"
import moment from 'moment'
import AnimateHeight from 'react-animate-height'
import './TimeRange.scss'
import { setProductTimeLine } from '../../store/actions/productSelections';

class TimeRange extends React.Component {

    dateTimeSelectionState = {
        START: 'start',
        END: 'end',
        NONE: 'none'
    }

    dateTimeToShow = {
        USER: 'user',
        TRUE: 'true',
        TEMP: 'temp'
    }

    startInputRef = React.createRef()
    startDateTimePickerRef = React.createRef()
    endInputRef = React.createRef()
    endDateTimePickerRef = React.createRef()

    state = {
        startDateTimeToShow: this.dateTimeToShow.TRUE,
        userStartString: '',
        isStartDateTimeValid: true,
        tempStart: {
            date: moment().utc().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).format('YYYY-MM-DD'),
            time: '00:00'
        },
        endDateTimeToShow: this.dateTimeToShow.TRUE,
        userEndString: '',
        isEndDateTimeValid: true,
        tempEnd: {
            date: moment().utc().add(1, 'day').set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).format('YYYY-MM-DD'),
            time: '00:00'
        },
        dateTimeSelectionState: this.dateTimeSelectionState.NONE,
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.outsideClickHandler, false)
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.outsideClickHandler, false)
    }

    handleTimelineSelectorChange = e => { //TODO: fix two clicks needed to disable datetimepicker when open
        if (e.target.value !== this.state.timelineType) {
            if (e.target.value === this.timelineTypes.NOMS) this.setState({
                timelineType: e.target.value,
                dateTimeSelectionState: this.dateTimeSelectionState.NONE
            })
            else this.setState({ timelineType: e.target.value })
        }
    }

    outsideClickHandler = e => { //TODO: refactor using material ui's outside click HOC
        if (
            !(this.startInputRef.current.contains(e.target) || this.startDateTimePickerRef.current.contains(e.target)) &&
            this.state.dateTimeSelectionState === this.dateTimeSelectionState.START
        ) this.closeStartDateTimePicker()
        else if (
            !(this.endInputRef.current.contains(e.target) || this.endDateTimePickerRef.current.contains(e.target)) &&
            this.state.dateTimeSelectionState === this.dateTimeSelectionState.END
        ) this.closeEndDateTimePicker()
    }

    handleStartFieldClick = e => {
        if (this.state.dateTimeSelectionState === this.dateTimeSelectionState.END) this.closeEndDateTimePicker(true)
        else if (this.state.dateTimeSelectionState === this.dateTimeSelectionState.NONE) this.setState({ dateTimeSelectionState: this.dateTimeSelectionState.START })
    }
    handleEndFieldClick = e => {
        if (this.state.dateTimeSelectionState === this.dateTimeSelectionState.START) this.closeStartDateTimePicker(true)
        else if (this.state.dateTimeSelectionState === this.dateTimeSelectionState.NONE) this.setState({ dateTimeSelectionState: this.dateTimeSelectionState.END })
    }

    handleStartDateChange = date => {
        const { productTimeLine } = this.props
        const startDate = moment(date).utc().startOf('day').format('YYYY-MM-DD')
        if (this.isEndDateTimeAfterStartDateTime(startDate, this.state.tempStart.time, productTimeLine.end.date, productTimeLine.end.time)) this.setState(state => {
            return {
                tempStart: { date: startDate, time: state.tempStart.time },
                startDateTimeToShow: this.dateTimeToShow.TEMP,
                userStartString: '',
                isStartDateTimeValid: true
            }
        })
        else this.setState(state => {
            return {
                tempStart: { date: startDate, time: this.state.tempStart.time },
                startDateTimeToShow: this.dateTimeToShow.TEMP,
                isStartDateTimeValid: false
            }
        })
    }

    handleStartTimeChange = time => {
        const { productTimeLine } = this.props
        if (this.isEndDateTimeAfterStartDateTime(this.state.tempStart.date, time, productTimeLine.end.date, productTimeLine.end.time)) this.setState(state => {
            return {
                tempStart: { date: state.tempStart.date, time: time },
                startDateTimeToShow: this.dateTimeToShow.TEMP,
                userStartString: '',
                isStartDateTimeValid: true
            }
        })
        else {
            this.setState(state => {
                return {
                    tempStart: { date: this.state.tempStart.date, time: time },
                    startDateTimeToShow: this.dateTimeToShow.TEMP,
                    isStartDateTimeValid: false
                }
            })
        }
    }

    handleStartDateTimeUserInput = e => {
        const { productTimeLine } = this.props
        if (moment(e.target.value, 'MMM D YYYY, HH:mm').format('MMM D YYYY, HH:mm') === 'Invalid date') this.setState({
            startDateTimeToShow: this.dateTimeToShow.USER,
            userStartString: e.target.value,
            isStartDateTimeValid: false
        })
        else {
            let d = moment(e.target.value, 'MMM D YYYY').format('YYYY-MM-DD')
            let t = moment(e.target.value, 'MMM D YYYY HH:mm').format('HH:mm')
            if (this.isEndDateTimeAfterStartDateTime(d, t, productTimeLine.end.date, productTimeLine.end.time)) this.setState({
                tempStart: { date: d, time: t },
                startDateTimeToShow: this.dateTimeToShow.USER,
                userStartString: e.target.value,
                isStartDateTimeValid: true
            })
            else this.setState({
                tempStart: { date: d, time: t },
                startDateTimeToShow: this.dateTimeToShow.USER,
                userStartString: e.target.value,
                isStartDateTimeValid: false
            })
        }
    }

    handleStartDateTimeKeyPress = e => { if (e.key === 'Enter' && this.state.dateTimeSelectionState === this.dateTimeSelectionState.START) this.closeStartDateTimePicker() }

    closeStartDateTimePicker = openEndDateTimePicker => {
        const { productTimeLine, setProductTimeLine } = this.props
        const { tempStart } = this.state

        const dateTimePicker = openEndDateTimePicker === true ? this.dateTimeSelectionState.END : this.dateTimeSelectionState.NONE
        if (this.state.isStartDateTimeValid) {

            setProductTimeLine({
                start: { date: tempStart.date, time: tempStart.time },
            })
            this.setState(state => {
                return {
                    startDateTimeToShow: this.dateTimeToShow.TRUE,
                    userStartString: '',
                    dateTimeSelectionState: dateTimePicker
                }
            })
        }
        else this.setState({
            tempStart: { date: productTimeLine.start.date, time: productTimeLine.start.time },
            startDateTimeToShow: this.dateTimeToShow.TRUE,
            userStartString: '',
            isStartDateTimeValid: true,
            dateTimeSelectionState: dateTimePicker
        })
    }

    handleEndDateChange = date => {
        const { productTimeLine } = this.props
        const endDate = moment(date).utc().startOf('day').format('YYYY-MM-DD')
        if (this.isEndDateTimeAfterStartDateTime(productTimeLine.start.date, productTimeLine.start.time, endDate, this.state.tempEnd.time)) this.setState(state => {
            return {
                tempEnd: { date: endDate, time: state.tempEnd.time },
                endDateTimeToShow: this.dateTimeToShow.TEMP,
                userEndString: '',
                isEndDateTimeValid: true
            }
        })
        else this.setState(state => {
            return {
                tempEnd: { date: endDate, time: this.state.tempEnd.time },
                endDateTimeToShow: this.dateTimeToShow.TEMP,
                isEndDateTimeValid: false
            }
        })
    }

    handleEndTimeChange = time => {
        const { productTimeLine } = this.props
        if (this.isEndDateTimeAfterStartDateTime(productTimeLine.start.date, productTimeLine.start.time, this.state.tempEnd.date, time)) this.setState(state => {
            return {
                tempEnd: { date: state.tempEnd.date, time: time },
                endDateTimeToShow: this.dateTimeToShow.TEMP,
                userEndString: '',
                isEndDateTimeValid: true
            }
        })
        else {
            this.setState(state => {
                return {
                    tempEnd: { date: this.state.tempEnd.date, time: time },
                    endDateTimeToShow: this.dateTimeToShow.TEMP,
                    isEndDateTimeValid: false
                }
            })
        }
    }

    handleEndDateTimeUserInput = e => {
        const { productTimeLine } = this.props
        if (moment(e.target.value, 'MMM D YYYY, HH:mm').format('MMM D YYYY, HH:mm') === 'Invalid date') this.setState({
            endDateTimeToShow: this.dateTimeToShow.USER,
            userEndString: e.target.value,
            isEndDateTimeValid: false
        })
        else {
            let d = moment(e.target.value, 'MMM D YYYY').format('YYYY-MM-DD')
            let t = moment(e.target.value, 'MMM D YYYY HH:mm').format('HH:mm')
            if (this.isEndDateTimeAfterStartDateTime(productTimeLine.start.date, productTimeLine.start.time, d, t)) this.setState({
                tempEnd: { date: d, time: t },
                endDateTimeToShow: this.dateTimeToShow.USER,
                userEndString: e.target.value,
                isEndDateTimeValid: true
            })
            else this.setState({
                tempEnd: { date: d, time: t },
                endDateTimeToShow: this.dateTimeToShow.USER,
                userEndString: e.target.value,
                isEndDateTimeValid: false
            })
        }
    }

    handleEndDateTimeKeyPress = e => { if (e.key === 'Enter' && this.state.dateTimeSelectionState === this.dateTimeSelectionState.END) this.closeEndDateTimePicker() }

    closeEndDateTimePicker = openStartDateTimePicker => {

        const { productTimeLine, setProductTimeLine } = this.props
        const { tempEnd } = this.state

        const dateTimePicker = openStartDateTimePicker === true ? this.dateTimeSelectionState.START : this.dateTimeSelectionState.NONE
        if (this.state.isEndDateTimeValid) {
            setProductTimeLine({
                end: { date: tempEnd.date, time: tempEnd.time },
            })
            this.setState(state => {
                return {
                    endDateTimeToShow: this.dateTimeToShow.TRUE,
                    userEndString: '',
                    dateTimeSelectionState: dateTimePicker
                }
            })

        } else {
            this.setState({
                tempEnd: { date: productTimeLine.end.date, time: productTimeLine.end.time },
                endDateTimeToShow: this.dateTimeToShow.TRUE,
                userEndString: '',
                isEndDateTimeValid: true,
                dateTimeSelectionState: dateTimePicker
            })
        }
    }
    render() {

        const { productTimeLine } = this.props
        const { tempStart, tempEnd } = this.state

        const getStartInputClasses = () => {
            let classes = []
            if (this.state.dateTimeSelectionState === this.dateTimeSelectionState.START) classes.push('selecting')
            if (!this.state.isStartDateTimeValid) classes.push('invalid')
            return classes.join(' ')
        }

        const getEndInputClasses = () => {
            let classes = []
            if (this.state.dateTimeSelectionState === this.dateTimeSelectionState.END) classes.push('selecting')
            if (!this.state.isEndDateTimeValid) classes.push('invalid')
            return classes.join(' ')
        }

        const getStartDateTimeString = () => {
            if (this.state.startDateTimeToShow === this.dateTimeToShow.TRUE) return this.formatDateTimeForDisplay(productTimeLine.start.date, productTimeLine.start.time)
            else if (this.state.startDateTimeToShow === this.dateTimeToShow.USER) return this.state.userStartString
            else if (this.state.startDateTimeToShow === this.dateTimeToShow.TEMP) return this.formatDateTimeForDisplay(tempStart.date, tempStart.time)
        }

        const getEndDateTimeString = () => {
            if (this.state.endDateTimeToShow === this.dateTimeToShow.TRUE) return this.formatDateTimeForDisplay(productTimeLine.end.date, productTimeLine.end.time)
            else if (this.state.endDateTimeToShow === this.dateTimeToShow.USER) return this.state.userEndString
            else if (this.state.endDateTimeToShow === this.dateTimeToShow.TEMP) return this.formatDateTimeForDisplay(tempEnd.date, tempEnd.time)
        }

        const getStartDateForCalendar = () => {
            if (this.state.startDateTimeToShow === this.dateTimeToShow.TRUE) return this.getCalenderAdjustedDate(new Date(productTimeLine.start.date))
            else return this.getCalenderAdjustedDate(new Date(tempStart.date))
        }

        const getEndDateForCalendar = () => {
            if (this.state.endDateTimeToShow === this.dateTimeToShow.TRUE) return this.getCalenderAdjustedDate(new Date(productTimeLine.end.date))
            else return this.getCalenderAdjustedDate(new Date(tempEnd.date))
        }

        return (
            <div className='TimeRange-container'>

                <div className='TimeRange-top-container'>
                    <div className='TimeRange-left-column'>
                        <label>Start:</label>
                        <input
                            type='text'
                            className={getStartInputClasses()}
                            id='TimeRange-startDate'
                            ref={this.startInputRef}
                            autoComplete="off"
                            value={getStartDateTimeString()}
                            onChange={this.handleStartDateTimeUserInput}
                            onKeyPress={this.handleStartDateTimeKeyPress}
                            onClick={this.handleStartFieldClick}
                        />
                        <label>End:</label>
                        <input
                            type='text'
                            className={getEndInputClasses()}
                            id='TimeRange-endDate'
                            ref={this.endInputRef}
                            autoComplete="off"
                            value={getEndDateTimeString()}
                            onChange={this.handleEndDateTimeUserInput}
                            onKeyPress={this.handleEndDateTimeKeyPress}
                            onClick={this.handleEndFieldClick}
                        />
                    </div>
                </div>
                <AnimateHeight duration={500} height={this.state.dateTimeSelectionState === this.dateTimeSelectionState.START ? 'auto' : 0}>
                    <div ref={this.startDateTimePickerRef} className='TimeRange-date-time-picker-container'>
                        <div className='TimeRange-date-picker-column'>
                            <DatePicker
                                selected={getStartDateForCalendar()}
                                onChange={this.handleStartDateChange}
                                inline
                            />
                        </div>
                        <div className='TimeRange-time-picker-column'>
                            <TimePicker value={this.state.startDateTimeToShow === this.dateTimeToShow.TRUE ? productTimeLine.start.time : tempStart.time} onChange={this.handleStartTimeChange}></TimePicker>
                            <div className='TimeRange-date-time-done' onClick={this.closeStartDateTimePicker}><span>Done</span></div>
                        </div>
                    </div>
                </AnimateHeight>
                <AnimateHeight duration={500} height={this.state.dateTimeSelectionState === this.dateTimeSelectionState.END ? 'auto' : 0}>
                    <div ref={this.endDateTimePickerRef} className='TimeRange-date-time-picker-container'>
                        <div className='TimeRange-date-picker-column'>
                            <DatePicker
                                selected={getEndDateForCalendar()}
                                onChange={this.handleEndDateChange}
                                inline
                            />
                        </div>
                        <div className='TimeRange-time-picker-column'>
                            <TimePicker value={this.state.endDateTimeToShow === this.dateTimeToShow.TRUE ? productTimeLine.end.time : tempEnd.time} onChange={this.handleEndTimeChange}></TimePicker>
                            <div className='TimeRange-date-time-done' onClick={this.closeEndDateTimePicker}><span>Done</span></div>
                        </div>
                    </div>
                </AnimateHeight>
                <div className='TimeRange-bottom-container'>
                </div>
            </div>
        )
    }

    getCalenderAdjustedDate = date => {
        const offsetHours = date.getTimezoneOffset() / 60
        return date.setHours(date.getHours() + offsetHours)
    }

    formatDateTimeForDisplay = (date, time) => {
        return moment.utc(date + ' ' + time + ':00', moment.ISO_8601).format('MMM D YYYY, HH:mm')
    }

    isEndDateTimeAfterStartDateTime = (startDate, startTime, endDate, endTime) => {
        const endDateTime = moment.utc(endDate + ' ' + endTime + ':00', moment.ISO_8601)
        const startDateTime = moment.utc(startDate + ' ' + startTime + ':00', moment.ISO_8601)
        return endDateTime.isAfter(startDateTime)
    }

}


const mapStateToProps = state => ({
    productTimeLine: state.productReducer.productTimeLine,
})

export default connect(mapStateToProps, {
    setProductTimeLine
})(TimeRange)
