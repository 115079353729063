export const radiansToDegrees = radians => radians * (180 / Math.PI)
export const degreesToRadians = degrees => degrees / (180 / Math.PI)

//gets n number of random elements from an array
export const getRandomFromArray = (arr, n) => {
    let result = new Array(n),
        len = arr.length,
        taken = new Array(len)
    if (n > len) return arr
    while (n--) {
        const x = Math.floor(Math.random() * len)
        result[n] = arr[x in taken ? taken[x] : x]
        taken[x] = --len in taken ? taken[len] : len
    }
    return result
}

export const getRandomInt = (min, max) => {
    min = Math.ceil(min)
    max = Math.floor(max)
    return Math.floor(Math.random() * (max - min + 1)) + min
}