import { REQUESTS, NOTES, PAST_REQUEST_SELECTED, DELETE_REQUEST} from '../actions/types';

  const initialState = {
    requests: [],
    request: {},
    notes: [],
    requestSelected: null
  };
  
  export default function(state = initialState, action) {
    switch (action.type) {
      case REQUESTS:
        return {
          ...state,
          requests: action.payload
        };
      case NOTES: 
        return {
          ...state,
          notes: action.payload
        }
      case PAST_REQUEST_SELECTED:
        return { 
          ...state,
          requestSelected: action.payload,
        };
      case DELETE_REQUEST:
        return {
          ...state,
          requests: state.requests.filter(item => item._id !== action.payload)
        };
      default:
        return state;
    }
  }
  


