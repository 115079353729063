import React from 'react'
import CloseIcon from '@material-ui/icons/Close'
import './list.scss'

const List = ({
    text,
    onClick,
    icon,
    styleState,
    Id,
}) => {

    let selected = false
    if (Array.isArray(styleState) && styleState.includes(Id)) selected = true
    else if (styleState === Id) selected = true

    return (
        <div onClick={() => { onClick(selected) }} className='list-component' style={{ background: selected ? '#484a49' : '' }}>
            <div className='left'>
                <div className='icon'>{icon}</div>
                <div className='text'> {text}</div>
            </div>
            <div className='right'>
                {selected && <CloseIcon />}
            </div>
        </div>
    )
}

export default List
