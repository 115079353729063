import { point, polygon } from '@turf/helpers'
import circle from '@turf/circle'
import booleanPointInPolygon from '@turf/boolean-point-in-polygon'
import booleanOverlap from '@turf/boolean-overlap'
import { radiansToDegrees } from './MathUtils'

export const selectionModes = {
    none: 'NONE',
    rectangle: 'RECTANGLE',
    circle: 'CIRCLE',
    point: 'POINT',
    waypoint: 'WAYPOINT'
}

export const selectionSources = {
    menu: 'MENU',
    map: 'MAP'
}

export const regionTypes = {
    shape: 'SHAPE',
    existingItem: 'EXISTING_ITEM'
}

export const productTypes = {
    available: 'AVAILABLE',
    requestable: 'REQUESTABLE'
}

export const getLatLonFromString = str => {
    const parsed = str.match(/[-\d.]+/g)
    if (!parsed) return false
    if (parsed.length !== 2) return false
    const latitude = parseFloat(parsed[0])
    const longitude = parseFloat(parsed[1])
    if (!latitude || !longitude) return false
    if (latitude < -90 || latitude > 90) return false
    if (longitude < -180 || longitude > 180) return false
    return { latitude, longitude }
}

export const getIntersectingRegions = (selection, regions) => {
    const intersectingRegions = []
    switch (selection.shape) {
        case selectionModes.point:
            const selectionPoint = point([selection.data.longitude, selection.data.latitude])
            regions.forEach(region => {
                const regionPolygon = polygon([region.coordinates])
                if (booleanPointInPolygon(selectionPoint, regionPolygon)) intersectingRegions.push(region)
            })
            break
        case selectionModes.rectangle:
            const selectionRect = polygon(getCoordsFromRectangle(selection.data))
            regions.forEach(region => {
                const regionPolygon = polygon([region.coordinates])
                if (booleanOverlap(selectionRect, regionPolygon)) intersectingRegions.push(region)
            })
            break
        case selectionModes.circle:
            const selectionCircle = circle(
                [selection.data.position.longitude, selection.data.position.latitude],
                selection.data.radius / 1000
            )
            regions.forEach(region => {
                const regionPolygon = polygon([region.coordinates])
                if (booleanOverlap(selectionCircle, regionPolygon)) intersectingRegions.push(region)
            })
            break
        case selectionModes.waypoint:
            const selectionPoints = selection.data.map(waypoint => point([waypoint.longitude, waypoint.latitude]))
            regions.forEach(region => {
                const regionPolygon = polygon([region.coordinates])
                if (selectionPoints.some(waypoint => booleanPointInPolygon(waypoint, regionPolygon))) intersectingRegions.push(region)
            })
            break
        default:
            break
    }
    return intersectingRegions
}

export const getCoordsFromRectangle = rect => {
    const west = radiansToDegrees(rect.west)
    const east = radiansToDegrees(rect.east)
    const north = radiansToDegrees(rect.north)
    const south = radiansToDegrees(rect.south)
    return [
        [
            [west, south],
            [east, south],
            [east, north],
            [west, north],
            [west, south]
        ]
    ]
}