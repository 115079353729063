import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import MuiSnackbar from '@material-ui/core/Snackbar'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import './Snackbar.scss'

const Snackbar = ({ snackbarData }) => {

    const [show, setShow] = useState(false)

    useEffect(() => {
        if (snackbarData.text) setShow(true)
    }, [snackbarData])

    return (
        <MuiSnackbar
            open={show}
            className={snackbarData.class}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            autoHideDuration={6000}
            onClose={() => { setShow(false) }}
            message={snackbarData.text}
            action={
                <React.Fragment>
                    <IconButton size="small" aria-label="close" color="inherit"
                        onClick={() => { setShow(false) }}
                    >
                        <CloseIcon fontSize="small" />
                    </IconButton>
                </React.Fragment>
            }
        />
    )
}

const mapStateToProps = ({ snackbarData }) => ({ snackbarData })

export default connect(mapStateToProps)(Snackbar)