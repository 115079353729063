import React from 'react'
import './Footer.scss'

const Footer = () => {
    return (
        <div className='Footer'>
            <span>
                Distro D: Further dissemination only as directed by Commander, Naval Meteorology and Oceanography Command or PEO IWS 5 or higher authority.
            </span>
        </div>
    )
}

export default Footer