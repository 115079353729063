import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { Link, useLocation, useHistory } from 'react-router-dom'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import PostAddIcon from '@material-ui/icons/PostAdd'
import { logoutUser } from '../../../store/actions/authActions'
import { clearProductSelections } from '../../../store/actions/productSelections'
import Snackbar from '../../Snackbar/Snackbar'
import { ReactComponent as Logo } from '../../../assets/NAVO-TANG_PI_white.svg'
import './mainNavBar.scss'


const MainNavBar = ({
  logoutUser,
  currentUser,
  clearProductSelections
}) => {

  const history = useHistory()
  const location = useLocation()
  const classes = useStyles()

  const logInOut = () => {
    if (currentUser.exp) {
      return <Button onClick={() => logoutUser()} color="inherit">Log Out</Button>
    } else {
      return <Link to='/login'> <Button color="inherit">Log In</Button> </Link>
    }
  }

  return (
    <div className='main-nav-wrapper'>
      <div>
        <AppBar >
          <Toolbar className={classes.root}>
            <Link to='/' className='title-container'>
              <Logo className='logo' />
              <Typography variant="h6" className={classes.title}>
                <span className='title'>Turbo METOC</span>
              </Typography>
            </Link>

            <div className='right-nav-items'>
              {location.pathname !== '/login' &&
                location.pathname !== '/new-request' &&
                <Button
                  color="inherit"
                  startIcon={<PostAddIcon />}
                  onClick={() => {
                    clearProductSelections()
                    history.push('/new-request')
                  }}
                >
                  New Request
                  </Button>
              }
              <div className='nab-btn'> {logInOut()}</div>
            </div>
          </Toolbar>
          <Snackbar />
        </AppBar>
      </div>
    </div>
  );
}


const mapStateToProps = state => ({
  currentUser: state.auth.currentUser
})

export default connect(mapStateToProps, { logoutUser, clearProductSelections })(MainNavBar)



const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    background: '#595A5A',
    justifyContent: 'space-between'
  },

  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));