import React from 'react'
import './headerBar.scss'

export const HeaderBar = ({
    text
}) => {
    return (
        <div className='header-bar'>
            {text}
        </div>
    )
}

export default HeaderBar
