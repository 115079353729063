//AUTH ACTION TYPES
export const GET_ERRORS = 'GET_ERRORS';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';

//PROFILES TYPES
export const GET_PROFILE = 'GET_PROFILE';
export const PROFILE_LOADING = 'GET_PROFILE_LOADING';
export const PROFILE_NOT_FOUND = 'GET_PROFILE_NOT_FOUND';
export const CLEAR_CURRENT_PROFILE = 'GET_CLEAR_CURRENT_PROFILE';
export const GET_PROFILES = 'GET_PROFILES';

//POST TYPES
export const POST_LOADING = 'POST_LOADING';
export const GET_POSTS = 'GET_POSTS';
export const GET_POST = 'GET_POST';
export const ADD_POST = 'ADD_POST';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';


//PRODUCT TYPES 
export const GET_PRODUCTS = 'GET_PRODUCTS';
export const CLEAR_PRODUCTS = 'CLEAR_PRODUCTS'
export const GET_OPTIONS = 'GET_OPTIONS';


//NOTES
export const NOTES = 'NOTES';

//REQUESTS TYPES
export const REQUESTS = 'REQUESTS';
export const PAST_REQUEST_SELECTED = 'PAST_REQUEST_SELECTED'

//PRODUCT SELECTION TYPES
export const PRODUCT_REGION = 'PRODUCT_REGION';
export const PRODUCT_REGION_FILTER_LIST = 'PRODUCT_REGION_FILTER_LIST';
export const PRODUCT_TIMELINE = 'PRODUCT_TIMELINE';
export const SET_SELECTED_PRODUCT = 'SET_SELECTED_PRODUCT'
export const CLEAR_ALL_PRODUCT_SELECTIONS = 'CLEAR_ALL_PRODUCT_SELECTIONS'
export const UPDATE_OPTIONAL_FIELD = 'UPDATE_OPTIONAL_FIELD'
export const DELETE_OPTIONAL_FIELD = 'DELETE_OPTIONAL_FIELD'
export const SET_EDIT_MODE = 'SET_EDIT_MODE'

//MAP SELECTION TYPES
export const SET_SELECTION_MODE = 'SET_SELECTION_MODE'
export const SET_SELECTION_POINT = 'SET_SELECTION_POINT'
export const CLEAR_SELECTION_POINT = 'CLEAR_SELECTION_POINT'
export const SET_SELECTION_RECT = 'SET_SELECTION_RECT'
export const SET_SELECTION_CIRCLE = 'SET_SELECTION_CIRCLE'
export const ADD_SELECTION_WAYPOINT = 'ADD_SELECTION_WAYPOINT'
export const CLEAR_ALL_SELECTIONS = 'CLEAR_ALL_SELECTIONS'
export const DELETE_REQUEST = 'DELETE_REQUEST'

//SNACKBAR NOTIFICATION TYPES
export const SET_SNACKBAR_DATA = 'SET_SNACKBAR_DATA'