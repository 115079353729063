
export const productAreaOptions = [
    { _id: 0, name: 'Meteorology Products' },
    { _id: 1, name: 'Oceanography Products' },
    { _id: 2, name: 'Tropical Applications' },
    { _id: 3, name: 'General Information' }
]

export const productCategoryOptions = [
    { _id: 1, name: 'Global, Regional, and Ensemble Weather Prediction Chart' },
    { _id: 2, name: 'Ensemble Forecast Charts' },
    { _id: 3, name: 'Ensemble Verification Metrics' },
    { _id: 4, name: 'Deterministic Tropical Verification Metrics' },
]

export const missionSetOptions = [
    { _id: 1, name: 'Aviation' },
    { _id: 2, name: 'Maritime Navigation' },
    { _id: 3, name: 'Resource Protection' },
    { _id: 4, name: 'Strike' },
]

export const missionCategoryOptions = [
    { _id: 1, name: 'Real World' },
    { _id: 2, name: 'Exercise' },
    { _id: 3, name: 'Training' },
    { _id: 4, name: 'Research' },
]

export const releaseToOptions = [
    { _id: 0, name: 'Great Britain' },
    { _id: 1, name: 'The United States' },
    { _id: 2, name: 'Australia' },
    { _id: 3, name: 'Canada' },
    { _id: 4, name: 'Japan' },
    { _id: 5, name: 'New Zealand' },
    { _id: 6, name: 'Norway' },
    { _id: 7, name: 'Switzerland' },
]

export const commandOptions = [
    { _id: 0, name: 'Command 1' },
    { _id: 1, name: 'Command 2' },
    { _id: 2, name: 'Command 3' },
]