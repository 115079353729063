import { combineReducers } from 'redux';
import authReducer from './authReducer';
import errorReducer from './errorReducer';
import profileReducer from './profileReducer';
import productReducer from './productReducer';
import requestsReducer from './requestsReducer';
import mapSelectionReducer from './mapSelectionReducer'
import snackbarReducer from './snackbarReducer'


export default combineReducers({
  auth: authReducer,
  errors: errorReducer,
  profile: profileReducer,
  productReducer: productReducer,
  requestsReducer: requestsReducer,
  mapSelection: mapSelectionReducer,
  snackbarData: snackbarReducer
});
