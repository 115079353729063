import {
  REQUESTS,
  NOTES,
  PAST_REQUEST_SELECTED,
  GET_ERRORS,
  DELETE_REQUEST
} from './types';
import { devBaseURL } from '../api';

//GET all requests by user /api/requests?userId={id}

export const getRequests = (userId) => async dispatch => {
  try {
    const res = await devBaseURL.get(`/api/requests`, { params: { userId: userId } });
    const requests = res.data.map(request => { //converting ISOStrings into JS Dates
      request.startDateTime = new Date(request.startDateTime)
      request.endDateTime = new Date(request.endDateTime)
      request.timestamp = new Date(request.timestamp)
      return request
    }).sort((a, b) => {
      const isDelivered = x => x.type === 'download' || x.status === 'delivered'
      const aDelivered = isDelivered(a)
      const bDelivered = isDelivered(b)
      if (aDelivered !== bDelivered) {
        if (aDelivered) return 1
        else return -1
      }
      return new Date(b.timestamp) - new Date(a.timestamp)
    })
    dispatch({
      type: REQUESTS,
      payload: requests
    })
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: { msg: err.response.statusText, status: err.response.status },
    })
  }
}


export const postRequest = requestObj => async dispatch => {
  return new Promise((resolve, reject) => {
    // console.log(requestObj)
    devBaseURL.post(`/api/requests`, requestObj)
      .then(r => {
        resolve(r)
      })
      .catch(e => {
        if (e.response && e.response.data) {
          console.warn(e.response.data)
          reject(e)
          dispatch({
            type: GET_ERRORS,
            payload: { msg: e.response.data, status: e.response.status },
          })
        }
      })
  })
}

//PUT (update) a request /api/requests/id
export const updateRequest = (requestId, requestObj) => async dispatch => {
  return new Promise((resolve, reject) => {
    devBaseURL.put(`/api/requests/${requestId}`, requestObj)
      .then(r => {
        resolve(r)
      })
      .catch(e => {
        if (e.response && e.response.data) {
          console.warn(e.response.data)
          reject(e)
          dispatch({
            type: GET_ERRORS,
            payload: { msg: e.response.data, status: e.response.status },
          })
        }
      })
  })
}

//mark all notes as read on a specific request (needs userId to no mark user's own notes as read)
export const markNotesAsRead = (id, userId) => async dispatch => {
  try {
    const res = await devBaseURL.put(`/api/requests/${id}/notes`, null, { params: { userId: userId } });
    dispatch({
      type: NOTES,
      payload: res.data
    })
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response ? { msg: err.response.statusText, status: err.response.status } : 'Error with no response text'
    })
  }
}

export const deleteRequest = (id) => async dispatch => {
  try {
    await devBaseURL.delete(`/api/requests/${id}`);
    dispatch({
      type: DELETE_REQUEST,
      payload: id
    })
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: { msg: err.response.statusText, status: err.response.status },
    })
  }
}

export const postNote = (note, Id) => async dispatch => {
  try {
    const res = await devBaseURL.post(`/api/requests/${Id}/notes`, note);
    dispatch({
      type: NOTES,
      payload: res.data
    })
  } catch (err) {
    console.log(err)
    dispatch({
      type: GET_ERRORS,
      payload: { msg: err.response.statusText, status: err.response.status },
    })
  }
}

export const setPastRequestSelected = (row) => async dispatch => {
  try {
    dispatch({
      type: PAST_REQUEST_SELECTED,
      payload: row
    })
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: { msg: err.response.statusText, status: err.response.status },
    })
  }
}
