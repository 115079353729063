import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import { getRequests, markNotesAsRead, setPastRequestSelected, deleteRequest } from '../../store/actions/requestsApi'
import { duplicateRequest } from '../../store/actions/productSelections'
import CreateIcon from '@material-ui/icons/Create';
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';
import VerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottom';
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import ChatMessages from '../chatMessages/ChatMessages';
import { regionTypes } from '../../utilities/mapUtils'
import './pendingRequestTable.scss'


const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: 'solid thin #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));



const PendingRequestTable = ({
  data,
  requests,
  getRequests,
  currentUser,
  markNotesAsRead,
  setPastRequestSelected,
  requestSelected,
  deleteRequest,
  duplicateRequest
}) => {

  const history = useHistory()

  const [open, setOpen] = useState(false);
  const [notesSelected, setNotesSelected] = useState({})
  const classes = useStyles();
  const [openDialog, setOpenDialog] = React.useState(false);
  const [deleteId, setDeleteId] = useState('')


  useEffect(() => { //TODO: this should happen at the dashboard page level
    getRequests(currentUser.user.id)
  }, [currentUser, getRequests])


  const handleOpen = (windowType, id) => {
    if (windowType === 'modal') {
      setOpen(true);
    } else {
      setDeleteId(id)
      setOpenDialog(true)
    };
  };

  const handleClose = (windowType) => {
    if (windowType === 'modal') {
      getRequests(currentUser.user.id)
      setOpen(false);
    } else {
      setOpenDialog(false)
      setDeleteId('')
    }
  };

  const deleteItem = () => {
    deleteRequest(deleteId)
    handleClose(true)
    setDeleteId('')
  }

  const rowBackground = (row) => {
    let selectedRowId;
    requestSelected ? selectedRowId = requestSelected._id : selectedRowId = null
    if (row._id === selectedRowId) {
      return '#1282CA' // blue
    }
  }

  const smeChatModal = (row) => {
    let updatedRequest = row.notes.map(note => {
      note.unread = false
      return note
    });
    row.notes = updatedRequest
    // markNotesAsRead(row._id, currentUser, { 'notes': row.notes })
    markNotesAsRead(row._id, currentUser.user.id)
    setNotesSelected(row)
    handleOpen('modal')
  }


  const modalBody = (
    <div className='modal-content'>
      <ChatMessages data={notesSelected} />
    </div>
  );


  const findUnread = (notes) => {
    const receivedNotes = notes.filter((note) => note = note.userId !== currentUser.user.id)
    const result = receivedNotes.some((element) => element.unread === true);
    return result
  }

  const statusList = (row)  => {
    const Statuses = ['draft', 'submitted', 'In Progress', 'delivered'];
    if(!row.status){
      return Statuses.map(item => <div key={item._id} className={item === 'delivered' ? 'step current'  : 'step'} >{item}</div>) }
      else{
        return Statuses.map(item => <div key={item._id} className={item === row.status ? 'step current'  : 'step'} >{item}</div>)

      }

  }

  return (
    <div>
      <div className='pending-request-table'>
        <TableContainer component={Paper}>
          <Table stickyHeader size='small' aria-label="customized table">
            <TableHead>
              <TableRow>
                <TableCell className='request-date-cell' align="center">Request Date</TableCell>
                <TableCell align="center">Product</TableCell>
                <TableCell align="center">Region</TableCell>
                <TableCell className='timeframe-cell' align="center">Time Frame</TableCell>
                <TableCell className='status-cell' align="center">Status</TableCell>
                <TableCell align="center">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {requests.map((row) => (
                <TableRow
                  key={row._id}
                  className={ rowBackground(row) ? 'request-table-row current' : 'request-table-row'}
                  style={{ background: rowBackground(row) }}
                  onClick={() => requestSelected && requestSelected._id === row._id ? setPastRequestSelected(null) : setPastRequestSelected(row)}
                >

                  <TableCell className='request-date-cell' align="center">{formatDate(row.timestamp)}</TableCell>
                  <TableCell align="center">{row.product.data.name}</TableCell>
                  <TableCell align="center">{row.region.type === regionTypes.existingItem ? row.region.data.name : 'Custom'}</TableCell>
                  <TableCell className='timeframe-cell'>
                    <span>{formatDate(row.startDateTime)}</span>
                    <span>{formatDate(row.endDateTime)}</span>
                  </TableCell>
                  <TableCell className='status-cell' align="center">  <div className='arrow-steps'> {statusList(row)} </div>   </TableCell>
                  <TableCell className='actions-cell' align="right">
                    <div className='button-container'>
                      {row.status === 'submitted' && <Button title='Edit' onClick={e => { history.push('/new-request'); duplicateRequest(row, true) }}><CreateIcon /></Button>}
                      {row.status !== 'submitted' && <Button title='Download'><VerticalAlignBottomIcon /></Button>}
                      <Button title='Duplicate' onClick={e => { history.push('/new-request'); duplicateRequest(row) }}><OpenInNewIcon /></Button>
                      {row.notes ? <Button title='Messages' className={findUnread(row.notes) ? 'red' : ''} onClick={() => smeChatModal(row)}><MailOutlineIcon /></Button> : ''}
                      <Button title='Delete' onClick={() => handleOpen('dialog', row._id)}><DeleteSweepIcon /></Button>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>


      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={() => handleClose('modal')}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          {modalBody}
        </Fade>
      </Modal>


      <Dialog
        open={openDialog}
        classes={{ paper: 'reset-alert' }}
        onClose={() => handleClose('dialog')}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete Request?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button className='cancel' onClick={() => handleClose('dialog')} color="primary">
            Cancel
          </Button>
          <Button className='confirm' onClick={() => deleteItem()} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}


const mapStateToProps = state => ({
  requests: state.requestsReducer.requests,
  currentUser: state.auth.currentUser,
  requestSelected: state.requestsReducer.requestSelected
});

export default connect(mapStateToProps, {
  getRequests,
  markNotesAsRead,
  setPastRequestSelected,
  deleteRequest,
  duplicateRequest
})(PendingRequestTable);



function formatDate(date) {
  return (date.getUTCMonth() + 1)
    + "/" + (date.getUTCDate())
    + "/" + date.getUTCFullYear()
    // + ' ' + date.getUTCHours().toString().padStart(2, '0')
    // + ':' + date.getUTCMinutes().toString().padStart(2, '0')
}