import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './store/reducers';
import { isChrome } from './utilities/browserutilities';

const middleware = [thunk];

 
/* eslint-disable no-underscore-dangle */
const composeEnhancers =
  process.env.NODE_ENV === 'development' && isChrome
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : null || compose
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(...middleware)))
/* eslint-enable */


// const store = createStore(
//   rootReducer,
//   initialState,
//   compose(
//     applyMiddleware(...middleware),
//     //  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
//     )
// );

export default store;