import React from 'react'
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import './ButtonDefault.scss';

 const ButtonDefault = ({
    value,
    type,
    variant,
    color
}) => {
    return (
        <Button 
        className='buttonDefault'
        variant={variant} 
        type={type} 
        color={color}
        >
        {value}
        </Button>
    )
}


ButtonDefault.propTypes = {
    value: PropTypes.string,
    variant: PropTypes.string,
    type: PropTypes.string
  };

  ButtonDefault.defaultProps = {
    value: 'text',
    variant: 'outlined',
    color: 'primary',
    type: 'button',
    disbled: false
  };

export default ButtonDefault;
