import {
    SET_SELECTION_MODE,
    SET_SELECTION_POINT,
    SET_SELECTION_RECT,
    SET_SELECTION_CIRCLE,
    ADD_SELECTION_WAYPOINT,
    CLEAR_ALL_SELECTIONS
} from './types'
import { setProductRegion } from './productSelections'
import { regionTypes, selectionModes } from '../../utilities/mapUtils'

export const setSelectionMode = mode => dispatch => {
    dispatch({
        type: SET_SELECTION_MODE,
        mode
    })
    dispatch(setProductRegion())
}

export const setSelectionPoint = (point, source) => dispatch => {
    dispatch({
        type: SET_SELECTION_POINT,
        point,
        source
    })
    dispatch(setProductRegion({
        title: 'Point: ' + point.latitude.toFixed(4) + '°, ' + point.longitude.toFixed(4) + '°',
        shape: selectionModes.point,
        data: point
    }, regionTypes.shape))
}

//this is only used to set rectangle from cesium to be read by other components. 
//cannot be used to set the cesium selector entity
export const setSelectionRect = rectangle => dispatch => {
    dispatch({
        type: SET_SELECTION_RECT,
        rectangle
    })
    dispatch(setProductRegion({
        title: 'Custom Rectangle',
        shape: selectionModes.rectangle,
        data: rectangle
    }, regionTypes.shape))
}

//this is only used to set circle from cesium to be read by other components. 
//cannot be used to set the cesium selector entity
export const setSelectionCircle = circle => dispatch => {
    dispatch(({
        type: SET_SELECTION_CIRCLE,
        circle
    }))
    dispatch(setProductRegion({
        title: 'Custom Circle',
        shape: selectionModes.circle,
        data: circle
    }, regionTypes.shape))
}

//this is only used to set waypoints from cesium to be read by other components. 
//cannot be used to set the cesium selector entity
export const addSelectionWaypoint = waypoint => (dispatch, getState) => {
    dispatch({
        type: ADD_SELECTION_WAYPOINT,
        waypoint
    })
    dispatch(setProductRegion({
        title: 'Custom Waypoints',
        shape: selectionModes.waypoint,
        data: getState().mapSelection.waypoints
    }, regionTypes.shape))
}

export const clearAllSelections = () => (dispatch, getState) => {
    const { clearSelectionEvent } = getState().mapSelection
    dispatch({
        type: CLEAR_ALL_SELECTIONS,
        clearSelectionEvent: !clearSelectionEvent
    })
}