import React from 'react'
import RequestCalendar from '../../../components/RequestCalendar/RequestCalendar'
import LocationMap from '../../../components/LocationMap/LocationMap'
import PendingRequestTable from '../../../components/PendingRequestTable/PendingRequestTable'
import PieChart from '../../../components/pieChart'
import BarChart from '../../../components/barChart'
import HeaderBar from '../../../components/common/HeaderBar/HeaderBar'
import SimpleBar from 'simplebar-react'
import MessageInbox from '../../../components/MessageInbox/MessageInbox'
import { mostRequestedProducts, productByAgency, productByModel } from '../../../data/notionalMetricData'
import './metocDashboard.scss'


const MetocDashboard = () => {

    return (
        <div className='metoc-dashboard'>
            <div className='top-row'>
                <div className='left-column'>
                    <HeaderBar text='Past Requests' />
                    <SimpleBar style={{ height: '100%' }}>
                        <PendingRequestTable />
                    </SimpleBar>
                </div>
                <div className='middle-column'>
                    <HeaderBar text='Past Request Times' />
                    <div className='calendar-wrapper'>
                    <RequestCalendar />
                    </div>
                </div>
                <div className='right-column'>
                    <HeaderBar text='Messages' />
                    <MessageInbox />
                </div>
            </div>

            <div className='bottom-row'>
                <div className='left-column'>
                    <HeaderBar text='Location Map' />
                    <LocationMap />
                </div>
                <div className='middle-column'>
                    <HeaderBar text='Metrics' />
                    <div className='pie-charts-container'>
                        <div className='piechart-wrapper'>
                            <PieChart data={productByAgency} titleText='Product Request By Agency' />
                        </div>
                        <div className='piechart-wrapper'>
                            <PieChart data={productByModel} titleText='Product Requests By Model' />
                        </div>
                    </div>
                </div>
                <div className='right-column'>
                    <HeaderBar text='Most Requested Products Last Month' />
                    <div className='bar-chart-wrapper'>
                        <BarChart data={mostRequestedProducts} />
                    </div>
                </div>
            </div>
        </div>
    )
}



export default MetocDashboard