var axios = require('axios')

const devBaseURL = axios.create({
    baseURL: process.env.NODE_ENV === 'production' ? process.env.PUBLIC_URL : 'http://localhost:5000',
    //Other custom seetings
}); 

const sampleURL = axios.create({
    baseURL: 'https://jsonplaceholder.typicode.com',
    //Other custom seetings
}); 
export { devBaseURL, sampleURL };
