import React, {useState} from "react"
import { connect } from 'react-redux'
import moment from 'moment'
import SimpleBar from 'simplebar-react'
import SendIcon from '@material-ui/icons/Send';
import {postNote} from '../../store/actions/requestsApi';
import './chatMessages.scss'


const ChatMessages = ({ data, currentUser, postNote }) => {
    
    
    const [note, setNote] = useState('')
    
    function formatDate(ISOString) {
        let date = new Date(ISOString)
        return (date.getMonth() + 1)
            + "/" + date.getDate()
            + "/" + date.getFullYear()
            + ' ' + date.getUTCHours().toString().padStart(2, '0')
            + ':' + date.getUTCMinutes().toString().padStart(2, '0')
    }

 
    
        const sendNote = () => {
            postNote({userId: currentUser.user.id, message: note}, data._id )
            setNote('')
            Notes.push({userId: currentUser.user.id, message: note, timestamp: moment().format()})
        }

        const Notes = data.notes

        const messages = Notes.map(item => {
            
            return <> {item.userId === currentUser.user.id ? <div key={item.message} className="message-wrap-sent">
                        <div className='message-signature'>  <span className='date'> {formatDate(item.timestamp)}</span> <span className='name'>{item.userName}</span> </div>
                        <div className="message-bubble">
                            {item.message}
                        </div>
                    </div>
                    : <div key={item.message}  className="message-wrap-received">
                    <div className='message-signature'> <span className='name'>{item.userName}</span> <span className='date'>{formatDate(item.timestamp)}</span>  </div>
                    <div className="message-bubble">
                    {item.message}
                    </div>
                </div>	
                
            }

        </>
        })


	    return (
        <div className="chat-box">
            <div className='chat-messages-wrapper'>
                <div className='messages-container'>
                <SimpleBar style={{height: '440px'}}> 
                {messages}
                </SimpleBar>
                </div>
            </div>
            <div className="message-sender">
                <input value={note} className='textBox' onChange={(e) => setNote(e.target.value)} type="text" placeholder='Send Message...'/>
                <div className='submitBtn'><SendIcon onClick={() => sendNote() } /></div>
            </div>
            
        </div>
		)
};

const mapStateToProps = state => ({
    currentUser: state.auth.currentUser
  });
  
  export default connect(mapStateToProps, { postNote })(ChatMessages);

