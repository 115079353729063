import React from 'react'
import './TimePicker.scss'
// import { SimpleBar } from 'react-custom-SimpleBar'
import SimpleBar from 'simplebar-react'

 
export default class TimePicker extends React.Component {

    hourScrollRef = React.createRef()
    minuteScrollRef = React.createRef()

    handleHourChange = e => {
        this.props.onChange(e.target.innerText + ':' + this.props.value.split(':')[1])
        // this.hourScrollRef.current.scrollTop(e.target.offsetTop) //to scroll to selected hour
    }

    handleMinuteChange = e => {
        this.props.onChange(this.props.value.split(':')[0] + ':' + e.target.innerText)
    }

    render() {
        const timeSplit = this.props.value.split(':')
        const selectedHour = timeSplit[0]
        const selectedMinute = timeSplit[1]

        return (
            <div className='TimePicker'>
                <div className='TimePicker-field'>{this.props.value}</div>


                <div className='TimePicker-list-container'>
                    <div className='left-time-selector'>
                    <SimpleBar style={{ height: '100%' }} ref={this.hourScrollRef}>
                        <ul className='TimePicker-hour-list'>
                            {[...Array(24).keys()].map(x => {
                                const str = x.toString().padStart(2, '0')
                                return (
                                    <li
                                        key={x}
                                        className={selectedHour === str ? 'selected' : ''}
                                        onClick={this.handleHourChange}
                                    >
                                        {str}
                                    </li>
                                )
                            })}
                        </ul>
                    </SimpleBar>
                    </div>

                    <div className='right-time-selector'>
                    <SimpleBar style={{ height: '100%' }} ref={this.minuteScrollRef}>
                        <ul className='TimePicker-minute-list'>
                        {[...Array(60).keys()].map(x => {
                                const str = x.toString().padStart(2, '0')
                                return (
                                    <li
                                        key={x}
                                        className={selectedMinute === str ? 'selected' : ''}
                                        onClick={this.handleMinuteChange}
                                    >
                                        {str}
                                    </li>
                                )
                            })}
                        </ul>
                    </SimpleBar>
                    </div>
                   
                    
                </div>

                
            </div>
        )
    }
}