import React from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import './ProductTile.scss'
import image1 from '../../assets/Product Tile Thumbnails/1.PNG'
import image2 from '../../assets/Product Tile Thumbnails/2.PNG'
import image3 from '../../assets/Product Tile Thumbnails/3.PNG'
import image4 from '../../assets/Product Tile Thumbnails/4.PNG'
import image5 from '../../assets/Product Tile Thumbnails/5.PNG'
import image6 from '../../assets/Product Tile Thumbnails/6.PNG'
import image7 from '../../assets/Product Tile Thumbnails/7.PNG'

const images = [image1, image2, image3, image4, image5, image6, image7]

const ProductTile = ({ data, index, onClick, selectedProduct }) => {

    return (
        <Tooltip placement='left' arrow classes={{ tooltip: 'ProductTile-tooltip' }}
            title={<div className='tooltip-content'>
                {data.region && <span>{data.region.name} | {data.model.name}</span>}
                <span>{data.name}</span>
                {data.snapshots && <span>{data.snapshots.length} Snapshots Available</span>}
                <span className='description'>This is where the description of the product would go. Users can inspect and understand the product before going to the checkout. Some more information would go here such as: The tropopause is the boundary in the Earth's atmosphere between the troposphere and the stratosphere. It is a thermodynamic gradient stratification layer, marking the end of the troposphere. It lies, on average, at 17 kilometres (11 mi) above equatorial regions, and about 9 kilometres (5.6 mi) over the polar regions.</span>
            </div>}
        >
            <div
                className={selectedProduct && data._id === selectedProduct.data._id ? 'ProductTile selected' : 'ProductTile'}
                onClick={onClick}
            >
                <div className='image' style={{ backgroundImage: `url(${images[index % images.length]})` }}>
                </div>
                <div className='textbox'>
                    <div className='name'>{data.name}</div>
                </div>
                {data.snapshots && <div className='badge'>{data.snapshots.length}</div>}
            </div>
        </Tooltip >
    )
}

export default ProductTile