import React, { useRef, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Viewer } from "resium"
import {
  Cartographic,
  Ellipsoid,
  Cartesian3,
  Color,
  PolygonHierarchy,
  GeometryInstance,
  PolygonGeometry,
  MaterialAppearance,
  Material,
  Rectangle,
  Primitive,
  HeightReference,
  defined,
  ArcType,
  CallbackProperty
} from "cesium"
import { setProductRegion } from '../../store/actions/productSelections'
import {
  setSelectionMode,
  setSelectionPoint,
  setSelectionRect,
  setSelectionCircle,
  addSelectionWaypoint,
  clearAllSelections
} from '../../store/actions/mapSelection'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import RoomIcon from '@material-ui/icons/Room'
import TimelineIcon from '@material-ui/icons/Timeline'
import Brightness1Icon from '@material-ui/icons/Brightness1'
import CropDinIcon from '@material-ui/icons/CropDin'
import { radiansToDegrees } from '../../utilities/MathUtils'
import { selectionModes, selectionSources, regionTypes } from '../../utilities/mapUtils'
import './cesiumMap.scss'


//common select variable
let tempCartesian = new Cartesian3()
let primitiveRefs = []

//point select variables
let pointSelectEntityRef
//rectangle select variables 
let rectSelectEntityRef
let rectangleSelector = new Rectangle()
let firstRectPointSet = false
let firstRectPoint = new Cartographic()
let tempCartographic = new Cartographic()
//circle select variables
let circleSelectEntityRef
let circleRadius = 500
const getCircleCoordsCallback = new CallbackProperty((time, result) => {
  return circleRadius
}, false)
//waypoint select variables
let waypointPolyLineEntityRef
let waypointEntityRefs = []
let waypointPositions = []
const getWaypointPositionsCallback = new CallbackProperty((time, result) => {
  return waypointPositions
}, false)

const polylineWidth = 2

const regionAppearance = new MaterialAppearance({
  material: Material.fromType('Color', {
    color: new Color(1, 0.5, 0.02, 0.2)
  })
})
const selectedRegionAppearance = new MaterialAppearance({
  material: Material.fromType('Color', {
    color: new Color(1, 0, 0, 0.5)
  })
})

const CesiumMap = ({
  regions,
  productRegion,
  productRegionFilterList,
  selectionMode,
  setSelectionMode,
  selectionPoint,
  setSelectionPoint,
  setSelectionRect,
  setSelectionCircle,
  selectionWaypoints,
  addSelectionWaypoint,
  clearAllSelections,
  clearSelectionEvent
}) => {

  const cesiumRef = useRef()
  const [isSelecting, setIsSelecting] = useState(false)
  const [showPrimitives, setShowPrimitives] = useState(false)
  // const [lastRegion, setLastRegion] = useState(null) // cycle selections

  //setting selectionPoint from outside component
  useEffect(() => {
    if (pointSelectEntityRef) {
      pointSelectEntityRef.show = true
      pointSelectEntityRef.position = Cartesian3.fromDegrees(selectionPoint.longitude, selectionPoint.latitude, 0, Ellipsoid.WGS84)
    }
  }, [selectionPoint])

  //setting selectionWaypoints from outside component
  useEffect(() => {
    if (waypointPolyLineEntityRef) {
      if (selectionWaypoints.length - waypointEntityRefs.length === 1) { //new incoming waypoint
        const point = selectionWaypoints[selectionWaypoints.length - 1]
        waypointEntityRefs.push(cesiumRef.current.cesiumElement.entities.add({
          position: Cartesian3.fromDegrees(point.longitude, point.latitude, 0, Ellipsoid.WGS84),
          point: {
            color: Color.WHITE,
            pixelSize: 10,
            show: true
          },
          id: 'WayPoint ' + (selectionWaypoints.length - 1),
        }))
        //update PolyLine
        if (selectionWaypoints.length > 0) {
          waypointPolyLineEntityRef.polyline.positions = new CallbackProperty((time, result) => {
            return selectionWaypoints.map(x => Cartesian3.fromDegrees(x.longitude, x.latitude, 0, Ellipsoid.WGS84))
          }, false)
          waypointPolyLineEntityRef.polyline.show = true
        }
      }
    }
  }, [selectionWaypoints])

  //setting default view and initializing selection entities
  useEffect(() => {
    cesiumRef.current.cesiumElement.scene.mode = 2
    cesiumRef.current.cesiumElement.scene.camera.position = new Cartesian3.fromDegrees(0, 0, 50)
    // cesiumRef.current.cesiumElement.scene.debugShowFramesPerSecond = true

    rectSelectEntityRef = cesiumRef.current.cesiumElement.entities.add({
      selectable: false,
      show: false,
      rectangle: {
        coordinates: new CallbackProperty((time, result) => {
          return Rectangle.clone(rectangleSelector, result)
        }, false),
        material: Color.RED.withAlpha(0.5)
      }
    })

    circleSelectEntityRef = cesiumRef.current.cesiumElement.entities.add({
      selectable: false,
      show: false,
      position: new Cartesian3.fromDegrees(0, 0),
      ellipse: {
        semiMinorAxis: getCircleCoordsCallback,
        semiMajorAxis: getCircleCoordsCallback,
        material: Color.RED.withAlpha(0.5),
      },
      id: 'selectionCircle',
    })

    pointSelectEntityRef = cesiumRef.current.cesiumElement.entities.add({
      position: Cartesian3.fromDegrees(0, 0),
      show: false,
      point: {
        color: Color.RED,
        outlineColor: Color.WHITE,
        outlineWidth: 1,
        pixelSize: 5,
        heightReference: HeightReference.NONE,
      },
      id: 'selectionPoint',
    })

    waypointPolyLineEntityRef = cesiumRef.current.cesiumElement.entities.add({
      polyline: {
        positions: getWaypointPositionsCallback,
        width: polylineWidth,
        arcType: ArcType.GEODESIC,
        material: Color.RED,
        show: false
      },
      id: 'selectionWaypointLine',
    })
  }, [])

  //creating region primitive list
  useEffect(() => {
    if (regions) {
      //remove old primitives
      primitiveRefs.forEach(primitive => {
        cesiumRef.current.cesiumElement.scene.primitives.remove(primitive)
      })
      primitiveRefs = []
      //add new primitives
      regions.forEach(region => {
        primitiveRefs.push(cesiumRef.current.cesiumElement.scene.primitives.add(new Primitive({
          geometryInstances: new GeometryInstance({
            geometry: new PolygonGeometry({
              polygonHierarchy: new PolygonHierarchy(
                Cartesian3.fromDegreesArray([].concat.apply([], region.coordinates)))
            }),
            id: region._id
          }),
          releaseGeometryInstances: false,
          appearance: regionAppearance,
          show: false,
        })))
      })
    }
  }, [regions])

  //show/hiding primitives based on filtered region list
  useEffect(() => {
    if (showPrimitives) {
      const filteredIds = productRegionFilterList.map(region => region._id)
      primitiveRefs.forEach(primitive => {
        if (filteredIds.includes(primitive.geometryInstances.id) ||
          (productRegion &&
            productRegion.type === regionTypes.existingItem &&
            primitive.geometryInstances.id === productRegion.data._id)) primitive.show = true
        else primitive.show = false
      })
    }
    // eslint-disable-next-line
  }, [productRegionFilterList])

  //show/hiding primitives based on selected product region
  useEffect(() => {
    if (productRegion && productRegion.type === regionTypes.existingItem) {
      const filteredIds = productRegionFilterList.map(region => region._id)
      primitiveRefs.forEach(primitive => {
        if (primitive.geometryInstances.id === productRegion.data._id) {
          primitive.show = true
          primitive.appearance = selectedRegionAppearance
        }
        else if (showPrimitives && filteredIds.includes(primitive.geometryInstances.id)) {
          primitive.show = true
          primitive.appearance = regionAppearance
        }
        else {
          primitive.show = false
          primitive.appearance = regionAppearance
        }
      })
    }
    else primitiveRefs.forEach(primitive => {
      primitive.show = false
      primitive.appearance = regionAppearance
    })
    // eslint-disable-next-line
  }, [productRegion])

  //clearing map selections
  useEffect(() => {
    pointSelectEntityRef.show = false
    rectangleSelector = new Rectangle()
    circleRadius = 500
    circleSelectEntityRef.show = false
    waypointEntityRefs.forEach(waypoint => cesiumRef.current.cesiumElement.entities.remove(waypoint))
    waypointEntityRefs = []
    waypointPolyLineEntityRef.polyline.positions = []
  }, [clearSelectionEvent])

  const getCartesian3FromClick = clickPosition => {
    const { scene } = cesiumRef.current.cesiumElement
    if (!scene) return
    const { ellipsoid } = scene.globe
    if (!clickPosition) {
      console.log("Point is undefined!")
      return
    }
    const cartesian = scene.camera.pickEllipsoid(clickPosition, ellipsoid)
    if (!cartesian) return
    else return cartesian
  }

  const getCartographicFromCartesian3 = cartesian3 => {
    const { scene } = cesiumRef.current.cesiumElement
    const { ellipsoid } = scene.globe
    if (!cartesian3) return
    const { latitude, longitude, height } = ellipsoid.cartesianToCartographic(cartesian3)
    return { latitude: radiansToDegrees(latitude), longitude: radiansToDegrees(longitude), height }
  }

  const handleClick = e => {

    // const getItemFromId = id => {
    //   return regions.find(region => region._id === id)
    // }

    primitiveRefs.forEach(primitive => { primitive.show = false })

    if (selectionMode === selectionModes.rectangle) startEndRectSelect()
    else if (selectionMode === selectionModes.circle) startEndCircleSelect(e)
    else if (selectionMode === selectionModes.point) handlePoint(e)
    else if (selectionMode === selectionModes.waypoint) handleWaypoint(e)
    // else if (selectionMode === selectionModes.none) {
    //   const selection = cesiumRef.current.cesiumElement.scene.drillPick(e.position)
    //   if (selection.length > 0) {
    //     if (lastRegion == null) // 1st Click
    //     {
    //       if (selection[0].id) {
    //         setLastRegion(selection[0].id)
    //         const item = getItemFromId(selection[0].id)
    //         if (!item) console.warn('Warning, no region match found for primitive selection')
    //         setProductRegion(item)
    //       }
    //       return

    //     } else // 2nd+ Click
    //     {
    //       if (selection.length > 1) {
    //         for (let index = 0; index < selection.length; index++) {
    //           if (selection[index].id === lastRegion) {
    //             if (index + 1 === selection.length) {
    //               index = -1
    //             }

    //             setLastRegion(selection[index + 1].id)
    //             const item = getItemFromId(selection[index + 1].id)
    //             if (!item) console.warn('Warning, no region match found for primitive selection')
    //             setProductRegion(item)
    //             return
    //           }
    //         }
    //       }

    //       setLastRegion(selection[0].id)
    //       const item = getItemFromId(selection[0].id)
    //       if (!item) console.warn('Warning, no region match found for primitive selection')
    //       setProductRegion(item)
    //       return
    //     }
    //   }
    //   setLastRegion(null)
    // }
  }

  const startEndRectSelect = () => {
    if (!isSelecting) { //start rect select
      rectSelectEntityRef.rectangle.show = true
    }
    else { //complete rect
      firstRectPointSet = false
      setSelectionRect(rectangleSelector)
    }
    setIsSelecting(!isSelecting)
  }

  const startEndCircleSelect = e => {

    if (!isSelecting) { //start circle select
      circleRadius = 500
      circleSelectEntityRef.show = true
      circleSelectEntityRef.position = getCartesian3FromClick(e.position)
    }
    else {
      setSelectionCircle({ position: getCartographicFromCartesian3(circleSelectEntityRef.position._value), radius: circleRadius })
    }
    setIsSelecting(!isSelecting)
  }

  const handlePoint = e => {
    setSelectionPoint(getCartographicFromCartesian3(getCartesian3FromClick(e.position)), selectionSources.map)
  }

  const handleWaypoint = e => {
    if (defined(e.position)) {
      addSelectionWaypoint(getCartographicFromCartesian3(getCartesian3FromClick(e.position)))
    }
  }

  const handleMouseMove = e => {
    if (isSelecting) {
      switch (selectionMode) {
        case selectionModes.rectangle:
          tempCartesian = cesiumRef.current.cesiumElement.scene.camera.pickEllipsoid(e.endPosition, cesiumRef.current.cesiumElement.scene.globe.ellipsoid)
          if (tempCartesian) {
            tempCartographic = Cartographic.fromCartesian(tempCartesian, Ellipsoid.WGS84)
            if (!firstRectPointSet) {
              Cartographic.clone(tempCartographic, firstRectPoint)
              firstRectPointSet = true
            }
            else {
              rectangleSelector.east = Math.max(tempCartographic.longitude, firstRectPoint.longitude)
              rectangleSelector.west = Math.min(tempCartographic.longitude, firstRectPoint.longitude)
              rectangleSelector.north = Math.max(tempCartographic.latitude, firstRectPoint.latitude)
              rectangleSelector.south = Math.min(tempCartographic.latitude, firstRectPoint.latitude)
              rectSelectEntityRef.show = true
            }
          }
          break
        case selectionModes.circle:
          tempCartesian = cesiumRef.current.cesiumElement.scene.camera.pickEllipsoid(e.endPosition, cesiumRef.current.cesiumElement.scene.globe.ellipsoid)
          if (tempCartesian && !Cartesian3.equals(circleSelectEntityRef.position._value, tempCartesian)) {
            circleRadius = Cartesian3.distance(circleSelectEntityRef.position._value, tempCartesian)
          }
          break
        default:
          break
      }
    }
  }

  const changeSelectionMode = (newMode) => {
    clearAllSelections()
    setSelectionMode(newMode)
  }

  const handleShowRegionsToggle = e => {
    const state = e.target.checked
    const selectedId = productRegion && productRegion.type === regionTypes.existingItem
      ? productRegion.data._id : null
    const filteredIds = productRegionFilterList.map(region => region._id)
    if (selectedId) primitiveRefs.forEach(primitive => {
      if (primitive.geometryInstances.id === selectedId) primitive.show = true
      else if (filteredIds.includes(primitive.geometryInstances.id)) primitive.show = state
      else primitive.show = false
    })
    else primitiveRefs.forEach(primitive => {
      if (filteredIds.includes(primitive.geometryInstances.id)) primitive.show = state
      else primitive.show = false
    })
    setShowPrimitives(state)
  }

  return (
    <>
      <div className='cesium-control-panel'>
        <div className='cesium-button-panel'>
          <div
            className={selectionMode === selectionModes.point ? 'cesium-custom-button selected' : 'cesium-custom-button'}
            onClick={() => { changeSelectionMode(selectionModes.point) }}
          ><RoomIcon /></div>
          <div
            className={selectionMode === selectionModes.rectangle ? 'cesium-custom-button selected' : 'cesium-custom-button'}
            onClick={() => { changeSelectionMode(selectionModes.rectangle) }}
          ><CropDinIcon /></div>
          <div
            className={selectionMode === selectionModes.circle ? 'cesium-custom-button selected' : 'cesium-custom-button'}
            onClick={() => { changeSelectionMode(selectionModes.circle) }}
          ><Brightness1Icon /></div>
          <div
            className={selectionMode === selectionModes.waypoint ? 'cesium-custom-button selected' : 'cesium-custom-button'}
            onClick={() => { changeSelectionMode(selectionModes.waypoint) }}
          ><TimelineIcon /></div>
        </div>
        <div className='region-toggle-container'>
          <FormControlLabel
            control={
              <Switch
                checked={showPrimitives}
                onChange={handleShowRegionsToggle}
                color='primary'
                name="Show Regions"
              />
            }
            label="Show Regions"
          />
        </div>
      </div>


      <Viewer
        full
        ref={cesiumRef}
        animation={false}
        homeButton={false}
        infoBox={false}
        timeline={false}
        selectionIndicator={false}
        geocoder={false}
        navigationInstructionsInitiallyVisible={false}
        navigationHelpButton={false}
        fullscreenButton={false}
        baseLayerPicker={false}
        creditDisplay={false}
        onClick={handleClick}
        onMouseMove={handleMouseMove}
      >
      </Viewer>
    </>
  )
}

const mapStateToProps = ({ productReducer, mapSelection }) => ({
  regions: productReducer.options ? productReducer.options.regions : null,
  productRegionFilterList: productReducer.productRegionFilterList,
  productRegion: productReducer.productRegion,
  selectionMode: mapSelection.mode,
  selectionPoint: mapSelection.point,
  selectionWaypoints: mapSelection.waypoints,
  clearSelectionEvent: mapSelection.clearSelectionEvent
})
export default connect(mapStateToProps, {
  setProductRegion,
  setSelectionMode,
  setSelectionPoint,
  setSelectionRect,
  setSelectionCircle,
  addSelectionWaypoint,
  clearAllSelections,
})(CesiumMap)