import React, { useRef, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { polygon } from '@turf/helpers'
import { Rectangle } from 'cesium'
import centerOfMass from '@turf/center-of-mass'
import { Tile as TileLayer, Heatmap as HeatmapLayer, Vector as VectorLayer } from 'ol/layer'
import { Circle as CircleStyle, Fill, Style } from 'ol/style';
import Map from "ol/Map.js"
import View from "ol/View.js"
import Stamen from 'ol/source/Stamen'
import { fromLonLat } from 'ol/proj'
import Feature from 'ol/Feature'
import Point from 'ol/geom/Point'
import VectorSource from "ol/source/Vector"
import sampleHeatmapData from '../Heatmap/2012_Earthquakes_Mag5.kml'
import { KML } from 'ol/format'
import { setPastRequestSelected } from '../../store/actions/requestsApi'
import { regionTypes, selectionModes } from '../../utilities/mapUtils'
import { radiansToDegrees } from '../../utilities/MathUtils'
import './LocationMap.scss'

let map = null

export const LocationMap = ({
    requests,
    requestSelected,
    setPastRequestSelected
}) => {

    const [showHeatMap, setShowHeatmap] = useState(false)

    const mapRef = useRef()
    //ref to allow click handler to get most recent props: 
    //https://stackoverflow.com/questions/62658932/why-cant-i-access-my-functional-components-props-within-an-event-handler
    const requestSelectedRef = useRef(requestSelected)
    requestSelectedRef.current = requestSelected

    //map initialization
    useEffect(() => {

        const features = requests.map(request => {
            const pointCoords = getCenter(request.region)
            if (pointCoords) {
                const feature = new Feature({ geometry: new Point(fromLonLat(pointCoords)) })
                feature.setId(request._id)
                return feature
            }
            else return undefined
        }).filter(x => typeof x !== 'undefined')
        // console.log(features)
        features.forEach(feature => {
            feature.setStyle(requestSelected && requestSelected._id === feature.getId()
                ? selectedPointStyle : pointStyle)
        })
        const vectorSource = new VectorSource({ features })

        const heatMapLayer = new HeatmapLayer({
            source: new VectorSource({
                url: sampleHeatmapData,
                format: new KML({
                    extractStyles: false,
                }),
            }),
            blur: 20,
            radius: 6,
            weight: function (feature) {
                const name = feature.get('name')
                const magnitude = parseFloat(name.substr(2))
                return magnitude - 5
            },
            gradient: ['#1282ca', '#fed727', '#f15a22'],
            visible: showHeatMap
        })
        if (map) {
            map.un('click', handleMapClick)
            map.setTarget(null)
        }
        const newMap = new Map({
            target: mapRef.current,
            layers: [
                new TileLayer({
                    source: new Stamen({ layer: 'toner' })
                }),
                new VectorLayer({
                    source: vectorSource,
                }),
                heatMapLayer
            ],
            view: new View({
                center: fromLonLat([10, 43]),
                zoom: 0
            })
        })
        newMap.on('click', handleMapClick)
        map = newMap

        function handleMapClick(e) {
            const feature = map.forEachFeatureAtPixel(e.pixel, (feature) => feature)
            if (feature) {
                const featureId = feature.getId()
                if (requestSelectedRef.current && requestSelectedRef.current._id === featureId) {
                    setPastRequestSelected(null)
                    map.getLayers().array_[1].getSource().getFeatures().forEach(feature => feature.setStyle(pointStyle))
                }
                else {
                    const found = requests.find(request => featureId === request._id)
                    if (found) {
                        setPastRequestSelected(found)
                        map.getLayers().array_[1].getSource().getFeatures().forEach(feature => {
                            const id = feature.getId()
                            if (id === featureId) feature.setStyle(selectedPointStyle)
                            else feature.setStyle(pointStyle)
                        })
                    }
                }
            }
        }
        // eslint-disable-next-line
    }, [requests])

    //updating selected request
    useEffect(() => {
        const selectedId = requestSelected ? requestSelected._id : null
        map.getLayers().array_[1].getSource().getFeatures().forEach(feature => {
            const id = feature.getId()
            if (id === selectedId) feature.setStyle(selectedPointStyle)
            else feature.setStyle(pointStyle)
        })
    }, [requestSelected])


    return (
        <div ref={mapRef} className='locationMap'>
            <button
                onClick={() => {
                    console.log(map.getLayers().array_[1].getSource().getFeatures()[0])
                    map.getLayers().array_[2].setVisible(!showHeatMap)
                    setShowHeatmap(!showHeatMap)
                }}
                className={!showHeatMap ? 'heatmap-btn' : 'heatmap-btn-on'}
                type="button"
                title="Heat Map"
            >
                Heat Map
            </button>
        </div>
    )
}


const mapStateToProps = state => ({
    requests: state.requestsReducer.requests,
    requestSelected: state.requestsReducer.requestSelected,
})

export default connect(mapStateToProps, { setPastRequestSelected })(LocationMap)

const pointStyle = new Style({
    image: new CircleStyle({
        radius: 8,
        fill: new Fill({
            color: '#1282CA',
        }),
    }),
})

const selectedPointStyle = new Style({
    image: new CircleStyle({
        radius: 8,
        fill: new Fill({
            color: '#e5018c',
        }),
    }),
})

const getCenter = region => {
    switch (region.type) {
        case regionTypes.existingItem:
            return centerOfMass(polygon([region.data.coordinates])).geometry.coordinates
        case regionTypes.shape:
            return calculateCustomCenter(region)
        default:
            return null
    }
}

const calculateCustomCenter = region => {
    switch (region.shape) {
        case selectionModes.rectangle: {
            const { longitude, latitude } = Rectangle.center(region.data)
            return [radiansToDegrees(longitude), radiansToDegrees(latitude)]
        }
        case selectionModes.circle: {
            const { longitude, latitude } = region.data.position
            return [longitude, latitude]
        }
        case selectionModes.point: {
            const { longitude, latitude } = region.data
            return [longitude, latitude]
        }
        case selectionModes.waypoint: {
            const { longitude, latitude } = region.data[0]
            return [longitude, latitude]
        }
        default:
            return null
    }
}