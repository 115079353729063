import React from 'react';
import TextField from '@material-ui/core/TextField';

import PropTypes from 'prop-types';
import './TextFieldInput.scss';

const TextFieldInput = ({
  name,
  placeholder,
  value,
  label,
  error,
  variant,
  type,
  onChange,
  disabled,
  helperText
}) => {
  return (
    <div className="form-input">
      <TextField
        type={type}
        name={name}
        value={value}
        onChange={onChange}
        disabled={disabled}
        label={label}
        variant={variant}
        error={error}
        helperText={helperText}
        placeholder={placeholder}

      />
    </div>
  );
};

TextFieldInput.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
  info: PropTypes.string,
  error: PropTypes.string,
  type: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.string,
  label: PropTypes.string,
  variant: PropTypes.string
};

TextFieldInput.defaultProps = {
  type: 'text'
};

export default TextFieldInput;