import React, { useState } from 'react'
import { connect } from 'react-redux';
import moment from 'moment'
// import TextFieldHorizontal from '../common/TextFieldHorizontal/TextFieldHorizontal'
import TextField from '@material-ui/core/TextField';
// import SearchIcon from '@material-ui/icons/Search'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { makeStyles } from '@material-ui/core/styles';
import ChatMessages from '../chatMessages/ChatMessages';
import { getRequests, markNotesAsRead } from '../../store/actions/requestsApi';
import './messageInbox.scss';
import SimpleBar from 'simplebar-react';


const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      paper: {
        backgroundColor: theme.palette.background.paper,
        border: 'solid thin #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
      },
  }));

 const MessageInbox = ({
    requests,
    getRequests,
    currentUser,
    markNotesAsRead
}) => {
   
    const [open, setOpen] = useState(false);
    const [ notesSelected, setNotesSelected ] = useState({})
    const [messageSearch, setMessageSearch] = useState('')

    const classes = useStyles();


    const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    getRequests(currentUser.user.id)
    setOpen(false);
  };

  const smeChatModal = (id) => {
    const filteredChat =  requests.filter(request => request._id === id)

    // let updatedRequestNotes = filteredChat[0].notes.map(note => {
    //     note.unread = false
    //     return note
    //   });
    markNotesAsRead(id, currentUser.user.id)

    setNotesSelected(filteredChat[0])
    handleOpen()
}

function formatDate(ISOString) {
    let date = new Date(ISOString)

    return (date.getMonth() + 1)
        + "/" + date.getDate()
        + "/" + date.getFullYear()
        + ' ' + date.getUTCHours().toString().padStart(2, '0')
        + ':' + date.getUTCMinutes().toString().padStart(2, '0')      
}


const allMessagesFiltered = () => {
    let messages = []
      requests.forEach(request => {
        const requestId = request._id;
        if(request.notes){
            request.notes.map(note => messages.push({ _id: requestId, ...note}))
        }
        const filtered =  messages.filter((item) => item.userId !== currentUser.user.id);
        messages = filtered
     })

        const NewArray = messages.sort((a, b) => {
                return moment(a.timestamp).diff(b.timestamp);
              }).reverse();
              return NewArray
    }// END allMessagesFiltered


    const messageList = () => {
       let MessageResults 
        if(requests){
            requests.forEach(request =>  {
                if(request.notes){
                  MessageResults =  allMessagesFiltered().map(data => {
                        return   <div key={data._id} className={!data.unread ? 'list-item' : 'list-item unread'} onClick={() => smeChatModal(data._id)}>
                            <div>
                                 <div className='name'><span><MailOutlineIcon/></span>{data.userName}</div>
                                 <div className='message'>{data.message}</div>
                            </div>
                             
                             <div>
                                 {formatDate(data.timestamp)}
                             </div>
                         </div>
                     })
                }else{
                    MessageResults = <div className='messages-placeholder'> <MailOutlineIcon/> <div>No Messages </div> </div>
                }
            })
        }else{
            MessageResults =  <div className='messages-placeholder'> <MailOutlineIcon/> <div>No Messages </div> </div>
        }
        return MessageResults
    }

    const modalBody = (
        <div className='modal-content'>
            <ChatMessages data={notesSelected}/>
        </div>
      );

    return (
        <div className='message-inbox'>

            <div className={requests ? 'search-messages' : 'search-messages-hiden'}>
                <div className='input-wrapper'>
                 <TextField
                    type='text'
                    // labelWidth='100%'
                    // label={<SearchIcon />}
                    placeholder={'Search Messages... '}
                    // variant='standard'
                    value={messageSearch}
                    fullWidth={true}
                    margin="none"
                    size='small'

                    onChange={(e) => setMessageSearch(e.target.value)}
                />
                </div>

            </div>
           
            <SimpleBar style={{ height: '80%' }}>
            {messageList()}
            </SimpleBar>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                timeout: 500,
                }}
                >
                <Fade in={open}>
                {modalBody}
                </Fade>
            </Modal>
        </div>
    )
}


const mapStateToProps = state => ({
    requests: state.requestsReducer.requests,
    currentUser: state.auth.currentUser
  });
  
  export default connect(mapStateToProps, { getRequests, markNotesAsRead: markNotesAsRead })(MessageInbox);
